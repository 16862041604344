/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  message,
  Input,
  Modal,
  Divider,
  Typography,
  Button,
  Select,
  Tooltip,
  Radio,
  InputNumber,
} from 'antd';
import 'react-phone-number-input/style.css';
import { withTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { v4 as uuidv4 } from 'uuid';
import partnerApi from '../../../services/partnerApi';
import {
  countryCodeOptions,
  errorCodes,
  httpResponseStatuses,
  indoContractTypeSelection,
  mobileCountryCodes,
  wageTypes,
} from '../../../constants';
import { colors } from '../../../styles/colors';

const { Item } = Form;
const { Option } = Select;
const { Title, Text } = Typography;

class PhoneInputWrapper extends React.Component {
  inputRef = React.createRef();

  render() {
    return <PhoneInput ref={this.inputRef} {...this.props} />;
  }
}

class InviteNewWorkersModal extends React.Component {
  state = {
    isSubmitting: false,
    errorDetail: '',
    mobiles: [{ fieldKey: uuidv4() }],
    useDigitalContract: false,
  };

  handleSubmit = () => {
    const { form, t, clientId, onUpdate, closeModal, locations, positions, contractRequired } = this.props;
    const { mobiles } = this.state;
    const { getFieldValue, setFields } = this.props.form;
    form.validateFieldsAndScroll(async (err, values) => {
      const location = locations.find(loc => loc.id === values.location);
      const position = positions.find(pos => pos.id === values.position);
      if (!err) {
        try {
          this.setState({ isSubmitting: true });
          const workers = mobiles.map(mobile => {
            return {
              first_name: values.firstName[mobile.fieldKey],
              last_name: values.lastName[mobile.fieldKey],
              mobile: values.mobile[mobile.fieldKey],
              email: values.email[mobile.fieldKey],
            };
          });
          let payload = {
            client: clientId,
            location: location?.id,
            position: position?.id,
            contract_required: false,
            workers,
          };
          if (contractRequired) {
            payload = {
              ...payload,
              contract_required: values.contractRequired,
              wage_amount: values.wageAmount,
              wage_type: values.wageType,
              contract_type: values.contractType,
            };
          }
          await partnerApi.bulkCreatePartners(payload);
          onUpdate();
          closeModal();
          message.success(t('Workers successfully invited and notified by Email'));
        } catch (error) {
          if (error.response?.status === httpResponseStatuses.BAD_REQUEST_400) {
            if ([errorCodes.MOBILE_ALREADY_EXISTS, errorCodes.INVALID_MOBILE].includes(error.response.data.code)) {
              this.setState({ errorDetail: error.response.data.detail });
            } else if (error.response.data.code === errorCodes.ACTIVE_EMPLOYMENT_ALREADY_EXISTS) {
              const errorDetail = error.response.data.detail;

              mobiles.forEach(mobile => {
                let key = 'mobile';
                let keyArray = errorDetail.mobile;

                if ('email' in errorDetail) {
                  key = 'email';
                  keyArray = errorDetail.email;
                }

                const fields = {};
                const value = getFieldValue(`${key}[${mobile.fieldKey}]`);

                // eslint-disable-next-line no-restricted-syntax
                for (const activeWorkerKey of keyArray) {
                  if (value === activeWorkerKey && key in errorDetail) {
                    fields[`${key}[${mobile.fieldKey}]`] = {
                      value,
                      errors: [new Error(t('activeEmploymentAlreadyExists'))],
                    };
                  }
                }
                setFields(fields);
              });
            }
          } else {
            message.warning(t('submitFailed'));
          }
        } finally {
          this.setState({ isSubmitting: false });
        }
      }
    });
  };

  addEmptyWorker = () => {
    const mobiles = this.state.mobiles.concat({ fieldKey: uuidv4() });
    this.setState({ mobiles });
  };

  removeWorker = selectedKey => {
    const mobiles = this.state.mobiles.filter((_, key) => key !== selectedKey);
    this.setState({ mobiles });
  };

  render() {
    const { visible, closeModal, t, locations, positions, country, contractRequired } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { isSubmitting, errorDetail, mobiles, useDigitalContract } = this.state;
    const contractOptions = [
      { value: true, label: t('contractManagementTrue') },
      { value: false, label: t('contractManagementFalse') },
    ];
    const wageTypeOptions = [
      { value: wageTypes.PER_HOUR.value, label: t('perHour') },
      { value: wageTypes.PER_DAY.value, label: t('perDay') },
      { value: wageTypes.PER_MONTH.value, label: t('perMonth') },
    ];

    const mobileCountryCodeLabels = {};
    Object.keys(mobileCountryCodes).forEach(key => {
      mobileCountryCodeLabels[key] = mobileCountryCodes[key].label;
    });

    return (
      <Modal
        width={1100}
        visible={visible}
        onCancel={() => {
          this.setState({ errorDetail: '' });
          closeModal();
        }}
        title={
          <Title level={4} style={{ margin: 0 }}>
            {t('inviteNewWorkers')}
          </Title>
        }
        okText={t('invite')}
        cancelText={t('cancel')}
        okButtonProps={{ type: 'v2-primary' }}
        confirmLoading={isSubmitting}
        onOk={e => {
          this.handleSubmit(e);
        }}
      >
        <Form hideRequiredMark colon={false} className="v2-form-wrapper">
          {contractRequired && (
            <>
              <Row>
                <Text strong>{t('contractManagement')}</Text>
              </Row>
              <Row style={{ marginBottom: '16px' }}>
                <Text>{t('contractManagementDescription')}</Text>
              </Row>
              <Row style={{ marginBottom: '16px' }}>
                {getFieldDecorator('contractRequired', {
                  rules: [{ required: true, message: t('This field is required') }],
                  initialvalue: true,
                })(
                  <Radio.Group onChange={e => this.setState({ useDigitalContract: e.target.value })}>
                    {contractOptions.map((option, index) => {
                      return (
                        <Radio key={index} value={option.value} disabled={option.disabled}>
                          {option.label}
                        </Radio>
                      );
                    })}
                  </Radio.Group>,
                )}
              </Row>
              {country.code === countryCodeOptions.INDONESIA && (
                <>
                  <Row>
                    <Text strong>{t('contractType')}</Text>
                  </Row>
                  <Row style={{ marginBottom: '16px' }}>
                    <Text>{t('chooseDirectHireContractType')}</Text>
                  </Row>
                  <Row style={{ marginBottom: '16px' }}>
                    {getFieldDecorator('contractType', {
                      rules: [{ required: useDigitalContract && true, message: t('This field is required') }],
                      initialvalue: true,
                    })(
                      <Radio.Group disabled={!useDigitalContract}>
                        {indoContractTypeSelection.map((option, index) => {
                          return (
                            <Radio key={index} value={option.value}>
                              {t(option.label)}
                            </Radio>
                          );
                        })}
                      </Radio.Group>,
                    )}
                  </Row>
                </>
              )}
              <Divider />
              <Row>
                <Text strong>{t('wageTypeAndAmount')}</Text>
              </Row>
              <Row style={{ marginBottom: '16px' }}>
                <Text>{t('wageTypeAndAmountDescription')}</Text>
              </Row>
              <Row gutter={10}>
                <Col span={12}>
                  <Item label={t('wageAmount')} style={{ marginBottom: 0 }}>
                    {getFieldDecorator('wageAmount', {
                      rules: [{ required: true, message: t('This field is required') }],
                    })(
                      <InputNumber
                        addonAfter={country.currency_code}
                        controls={false}
                        style={{ marginRight: 8, marginTop: 4 }}
                        placeholder={t('wageAmountPlaceholder')}
                      />,
                    )}
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label={t('wageType')} style={{ marginBottom: 0 }}>
                    {getFieldDecorator('wageType', {
                      rules: [{ required: true, message: t('This field is required') }],
                      initialvalue: wageTypes.PER_DAY.value,
                    })(
                      <Select filterOption={false}>
                        {wageTypeOptions.map(({ value, label }) => (
                          <Option key={value} value={value} initialValue={wageTypes.PER_DAY.value}>
                            {label}
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </Item>
                </Col>
              </Row>
              <Divider />
            </>
          )}
          <Row>
            <Text strong>{t('inviteLocationAndPosition')}</Text>
          </Row>
          <Row style={{ marginBottom: '8px' }}>
            <Text>{t('inviteLocationAndPositionDescription')}</Text>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Item label={t('location')} style={{ marginBottom: 0 }}>
                {getFieldDecorator('location', {
                  rules: [{ required: true, message: t('This field is required') }],
                })(
                  <Select placeholder={t('selectLocation')} filterOption={false}>
                    {locations.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label={t('position')} style={{ marginBottom: 0 }}>
                {getFieldDecorator('position', {
                  rules: [{ required: true, message: t('This field is required') }],
                })(
                  <Select placeholder={t('selectPosition')} filterOption={false}>
                    {positions.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Text strong>{t('inviteNewWorkersTitle')}</Text>
          </Row>
          <Row style={{ marginBottom: '16px' }}>
            <Text>{t('inviteNewWorkersBody')}</Text>
          </Row>
          {mobiles.map((mobile, key) => {
            const selectedCountryCode = typeof mobile.country !== 'undefined' ? mobile.country : country.code;
            return (
              <Row key={key}>
                <Col>
                  <Item style={{ marginBottom: 0, marginRight: '8px', marginLeft: '8px' }}>{key + 1}.</Item>
                </Col>
                <Col>
                  <Item style={{ marginBottom: 0, marginRight: '8px' }}>
                    {getFieldDecorator(`firstName[${mobile.fieldKey}]`, {
                      rules: [{ required: true, message: t('This field is required') }],
                    })(<Input placeholder={t('firstName')} />)}
                  </Item>
                </Col>
                <Col>
                  <Item style={{ marginBottom: 0, marginRight: '8px' }}>
                    {getFieldDecorator(`lastName[${mobile.fieldKey}]`, {
                      rules: [{ required: true, message: t('This field is required') }],
                    })(<Input placeholder={t('lastName')} />)}
                  </Item>
                </Col>
                <Col>
                  <Tooltip
                    placement="right"
                    title={
                      mobileCountryCodes[selectedCountryCode]
                        ? `${selectedCountryCode} mobile number is at least ${mobileCountryCodes[selectedCountryCode]?.length}
                          digits e.g. ${mobileCountryCodes[selectedCountryCode]?.example}`
                        : ''
                    }
                  >
                    <Item style={{ marginBottom: 0, marginRight: '8px' }}>
                      {getFieldDecorator(`mobile[${mobile.fieldKey}]`, {
                        validateFirst: true,
                        rules: [
                          { required: true, message: t('This field is required') },
                          {
                            validator: (_, value) => isValidPhoneNumber(value, country.code),
                            message: t('Number is not valid'),
                          },
                        ],
                        initialvalue: mobile?.value,
                      })(
                        <PhoneInputWrapper
                          international
                          addInternationalOption={false}
                          countries={Object.keys(mobileCountryCodes)}
                          labels={mobileCountryCodeLabels || ''}
                          defaultCountry={country.code}
                          onCountryChange={countryValue => {
                            const mobilesValue = [...this.state.mobiles];
                            mobilesValue.splice(key, 1, { ...mobilesValue[key], countryValue });
                            this.setState({ mobiles: mobilesValue });
                          }}
                          numberInputProps={{ className: 'ant-input' }}
                          style={{ margin: '4px auto' }}
                        />,
                      )}
                    </Item>
                  </Tooltip>
                </Col>
                <Col>
                  <Item type="email" style={{ marginBottom: 0, marginRight: '8px' }}>
                    {getFieldDecorator(`email[${mobile.fieldKey}]`, {
                      rules: [
                        { required: true, message: t('This field is required.') },
                        {
                          type: 'email',
                          message: t('Email is not valid.'),
                        },
                      ],
                    })(<Input placeholder={t('Email')} />)}
                  </Item>
                </Col>
                <Col>
                  {mobiles.length > 1 && (
                    <Button onClick={() => this.removeWorker(key)} style={{ marginTop: 4, padding: 'auto 10px' }}>
                      <CloseOutlined style={{ color: colors.red }} />
                    </Button>
                  )}
                </Col>
              </Row>
            );
          })}
          <Row>
            <Text style={{ color: colors.red }}>{errorDetail}</Text>
          </Row>
          <Button type="link" icon={<PlusCircleOutlined />} onClick={this.addEmptyWorker} style={{ padding: 0 }}>
            <span style={{ textDecoration: 'underline' }}>{t('addAnotherWorker')}</span>
          </Button>
        </Form>
      </Modal>
    );
  }
}
export default Form.create()(withTranslation()(InviteNewWorkersModal));
