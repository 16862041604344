import React from 'react';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import { Row, Card, Typography, Descriptions } from 'antd';

import staffRequestUtils from '../../../../utilities/staffRequestUtils';
import textUtils from '../../../../utilities/textUtils';
import { DATE_WITH_DAY_FORMAT } from '../../../../constants';

const { Text } = Typography;

const SrSummaryCard = ({ staffRequest, t }) => {
  const { timezone } = staffRequest.location?.address.area.city;
  return (
    <Card bordered={false} style={{ backgroundColor: 'inherit' }} bodyStyle={{ padding: 0 }}>
      <Row style={{ margin: '8px 0 16px 0' }}>
        <Text strong type="secondary" style={{ fontSize: '12px' }}>
          {t('Staff request summary'.toUpperCase())}
        </Text>
      </Row>
      <Descriptions layout="vertical" colon={false} column={{ md: 2, sm: 1, xs: 1 }} size="small">
        <Descriptions.Item label={t('Title')} span={2}>
          {staffRequest.title}
        </Descriptions.Item>
        <Descriptions.Item label={t('Position')}>{staffRequest.position.name}</Descriptions.Item>
        <Descriptions.Item label={t('Number of staff')}>{staffRequest.staff_required}</Descriptions.Item>
        <Descriptions.Item label={t('Wage type')}>
          {t(textUtils.makeFriendly(staffRequest.wage_type))}
        </Descriptions.Item>
        <Descriptions.Item label={t('Wage amount')}>
          {staffRequestUtils.wageToString(staffRequest, t)}
        </Descriptions.Item>
        <Descriptions.Item label={t('Location')} span={2}>
          {staffRequest.location.name}
        </Descriptions.Item>
        <Descriptions.Item label={t('Period')} span={2}>
          {moment.tz(staffRequest.start_time, timezone).format(DATE_WITH_DAY_FORMAT)} -{' '}
          {moment.tz(staffRequest.end_time, timezone).format(DATE_WITH_DAY_FORMAT)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default withTranslation()(SrSummaryCard);
