import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Row, Typography } from 'antd';
import authApi from '../../services/authApi';
import { colors } from '../../styles/colors';
import logo from '../../assets/images/brand_logo_dark.svg';
import routes from '../../routes';

const { Text } = Typography;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 24px;
`;

const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  box-shadow: 0px 5px 20px 0px #4d4d4d26;
  text-align: center;
  max-width: 432px;
  padding: 48px 24px 24px 24px;
`;
class ForgotPasswordPage extends React.Component {
  state = {
    submitted: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const data = await authApi.forgotPassword(values.email);
        if (data) {
          this.setState({ submitted: true });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { submitted } = this.state;
    const { t } = this.props;
    return (
      <Layout>
        <img src={logo} alt="Brand Logo" style={{ height: '24px', marginBottom: '24px' }} />

        <Card>
          <TitleWrapper>
            <Text style={{ fontSize: '20px', fontWeight: '600' }}>{t('resetPassword')}</Text>
            <Text>{t('resetPasswordDescription')}</Text>
          </TitleWrapper>

          {submitted ? (
            <Row type="flex" justify="start">
              <Text style={{ paddingBottom: '24px', fontSize: '14px' }}>{t('pleaseCheckEmail')}</Text>
            </Row>
          ) : (
            <Form hideRequiredMark layout="vertical" onSubmit={this.handleSubmit} colon={false}>
              {/* Forgot Password Form */}
              <Form.Item hasFeedback style={{ width: '100%', marginBottom: '24px' }}>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: t('Please input your email.') }],
                })(<Input size="large" placeholder={t('businessEmail')} />)}
              </Form.Item>

              <Button
                block
                size="large"
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: colors.workmateGreen,
                  border: `1px solid ${colors.borderGrey}`,
                  marginBottom: '24px',
                }}
              >
                {t('sendPassword')}
              </Button>
            </Form>
          )}
          <Text
            underline
            style={{ color: colors.functionalLink, cursor: 'pointer' }}
            onClick={() => {
              this.props.history.push(routes.login);
            }}
          >
            {t('backToLogin')}
          </Text>
        </Card>
      </Layout>
    );
  }
}
export default Form.create()(withTranslation()(ForgotPasswordPage));
