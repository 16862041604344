import React, { Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import { PlusCircleOutlined, DownOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Steps,
  Tooltip,
  Typography,
  AutoComplete,
  Switch,
  Tag,
  TimePicker,
} from 'antd';
import moment from 'moment-timezone';
import { debounce, isEmpty, omit } from 'lodash';

import numeral from 'numeral';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import routes from '../../../routes';
import staffRequestApi from '../../../services/staffRequestApi';
import clientApi from '../../../services/clientApi';
import staffRequestUtils from '../../../utilities/staffRequestUtils';

import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import {
  staffRequestStatuses,
  roles,
  wageTypes,
  paymentFrequency,
  employmentTypes,
  DATE_WITH_DAY_FORMAT,
  seenModals,
  countryCodeOptions,
  TAFEP_GUIDELINES_LINK,
  recurrenceOptions,
  TIME_FORMAT_24_HOUR,
  userStatuses,
  TIME_FORMAT_24_HOUR_WITH_SECONDS,
  staffRequestTemplateStatuses,
  interviewOptionKeys,
  indoContractTypeSelection,
} from '../../../constants';
import textUtils from '../../../utilities/textUtils';
import { colors } from '../../../styles/colors';
import LocationFormModal from '../../SettingsPage/LocationsSection/components/LocationFormModal';
import formsApi from '../../../services/formsApi';
import ApplicationForms from '../../../containers/ApplicationForms';
import previewIcon from '../../../assets/images/preview-outline.svg';
import ErrorPage from '../../../shared/layouts/ErrorPage';
import staffRequestTemplateApi from '../../../services/staffRequestTemplateApi';
import SaveAsJobTemplateModal from '../components/SaveAsJobTemplateModal';
import UpdateCurrentTemplate from '../components/UpdateCurrentTemplateModal';
import formUtils from '../../../utilities/formUtils';

const numberInputDelay = 300;
const textInputDelay = 600;

const colSpan = 16;

const { Step } = Steps;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { Panel } = Collapse;

const fields = {
  EMPLOYMENT_TYPE: 'employment_type',
  WAGE_TYPE: 'wage_type',
};

class StaffRequestFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isDuplicate: false,
      hasExistingContractType: false,
      // TODO the default values of each field need adding here
      staffRequest: {
        title: undefined,
        responsibilities: undefined,
        requirements: undefined,
        position: undefined,
        require_experience: false,
        staff_required: 1,
        wage_type: wageTypes.PER_DAY.value,
        wage_amount: undefined,
        employment_type: employmentTypes.FULL_TIME.value,
        transport_allowance: undefined,
        meal_allowance: undefined,
        other_allowance: undefined,
        payment_frequency: undefined,
        start_time: moment(),
        form_id: undefined,
        first_day_message: undefined,
        working_days: [0, 1, 2, 3, 4],
        begin_time: undefined,
        finish_time: undefined,
        unpaid_break: true,
        break_duration_in_minutes: 0,
        auto_hire: undefined,
        client_interview_required: undefined,
        public_holiday_wage: undefined,
        public_holiday_weekend_wage: undefined,
      },
      vipWorkerCount: 0,
      staffRequestTemplates: [],
      selectedTemplate: null,
      currencyCode: this.props.user.country.currency_code,
      isLoading: false,
      isSubmitting: false,
      locationModalOpen: false,
      timezone: undefined,
      currentStep: 0,
      firstDayMessageToggle: true,
      applicationFormToggle: true,
      selectedApplicationForm: undefined,
      saveAsJobTemplateModalOpen: false,
      updateJobTemplateModalOpen: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const { history, location, user } = this.props;

    const isDuplicate = location.state && location.state.replicateId;
    const urlParts = history.location.pathname.split('/');
    const isEdit = urlParts.includes('edit');

    const isWorkmateAccount = staffRequestUtils.isWorkmateAccount(user?.username);

    if (isEdit) {
      const { id } = this.props.match.params;
      await this.initializeForm(id, isWorkmateAccount, isEdit);
      this.setState({ isEdit: true });
    }
    if (isDuplicate) {
      // Copy from an existing SR
      const id = location.state.replicateId;
      await this.initializeForm(id, isWorkmateAccount);

      // Append title with "copy of" for the duplicate SR
      this.setState(prevState => ({
        staffRequest: {
          ...prevState.staffRequest,
          id: undefined,
          title: `Copy of ${prevState.staffRequest.title}`,
        },
        isDuplicate: true,
      }));
    }
    const staffRequestTemplates = await staffRequestTemplateApi.fetchStaffRequestTemplates({
      employment_type: `${employmentTypes.CONTRACT.value},${employmentTypes.FULL_TIME.value},${employmentTypes.PART_TIME.value}`,
      status: staffRequestTemplateStatuses.POSTED,
      page_size: 1000,
    });

    this.setState({
      isLoading: false,
      staffRequestTemplates: staffRequestTemplates?.results || [],
    });
  }

  getAndSetVipWorkerCount = async position => {
    const { t, user, form } = this.props;
    let vipWorkerCount = 0;
    try {
      if (position?.id && user?.clientId) {
        const response = await clientApi.fetchVipWorkers(user.clientId, position.id);
        vipWorkerCount = response?.count || 0;
        this.setState({ vipWorkerCount });
      }
      form.validateFields(['vip_workers_only']);
    } catch (error) {
      Sentry.captureException(error);
      message.warning(t('An unexpected error occurred'));
    }
  };

  initializeForm = async (id, isWorkmateAccount, isEdit = false) => {
    const { t } = this.props;
    const staffRequest = await staffRequestApi.fetchStaffRequestById(id);
    const isDraftOrPendingReview = [staffRequestStatuses.DRAFT, staffRequestStatuses.PENDING_REVIEW].includes(
      staffRequest?.status,
    );

    if ((!isEdit || isDraftOrPendingReview) && !isWorkmateAccount) {
      staffRequest.form_id = null;
    }

    if (!isEmpty(staffRequest?.working_days)) {
      staffRequest.working_days = staffRequestUtils.workingDaysToIntArray(staffRequest.working_days);
    }

    if (staffRequest?.wage_type === wageTypes.PER_MONTH) {
      staffRequest.transport_allowance = undefined;
      staffRequest.meal_allowance = undefined;
      staffRequest.other_allowance = undefined;
    }

    const state = {
      staffRequest,
      timezone: staffRequest.location.address.area.city.timezone,
      firstDayMessageToggle: staffRequest?.first_day_message,
      applicationFormToggle: Boolean(staffRequest.form_id),
      hasExistingContractType: !isEmpty(staffRequest?.contract_type),
    };
    if (staffRequest.form_id) {
      try {
        const applicationForm = await formsApi.fetchFormById(staffRequest.form_id);
        if (applicationForm) {
          state.selectedApplicationForm = applicationForm;
        }
      } catch (error) {
        Sentry.captureException(error);
        message.warning(t('Sorry! An error occured in submission, please contact support'));
      }
    }
    this.setState(state);
  };

  handleCancel = e => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleAutoSelect = async (selection, field) => {
    const { user, positions } = this.props;
    if (field === 'position') {
      const position = positions.find(positionValue => positionValue.name === selection);
      this.setState(
        prevState => {
          const { staffRequest } = prevState;
          staffRequest.position = position;
          return { staffRequest };
        },
        () => this.getAndSetVipWorkerCount(position),
      );
    } else if (field === 'location') {
      const location = user.locations.find(({ name }) => name === selection);
      this.setState(prevState => {
        const { staffRequest } = prevState;
        staffRequest.location = location;
        return { staffRequest, timezone: location.address.area.city.timezone };
      });
    }
  };

  handleSelectInput = (selectedInput, field) => {
    this.setState(prevState => {
      const { staffRequest } = prevState;
      staffRequest[field] = selectedInput;
      return { staffRequest };
    });
  };

  handleAddTitle = title => {
    this.setState(prevState => {
      const { staffRequest } = prevState;
      staffRequest.title = title.currentTarget.value;
      return { staffRequest };
    });
  };

  handleAddSrDetails = (text, field) => {
    this.setState(prevState => {
      const { staffRequest } = prevState;
      staffRequest[field] = text.currentTarget.value;
      return { staffRequest };
    });
  };

  handleAddRequireWorkExp = isChecked => {
    this.setState(prevState => {
      const { staffRequest } = prevState;
      staffRequest.require_experience = isChecked;
      return { staffRequest };
    });
  };

  handleAutohireChange = isChecked => {
    this.setState(prevState => {
      const { staffRequest } = prevState;
      staffRequest.auto_hire = isChecked;
      return { staffRequest };
    });
  };

  handleTypeChange = (e, field) => {
    const { getFieldValue } = this.props.form;
    if ([fields.EMPLOYMENT_TYPE, fields.WAGE_TYPE].includes(field)) {
      if (field === fields.EMPLOYMENT_TYPE) {
        const employmentTypeField = getFieldValue(fields.EMPLOYMENT_TYPE);
        // There is a delay passing through the new field value, so check for previous
        if (
          employmentTypeField === employmentTypes.DAILY.value ||
          employmentTypeField === employmentTypes.CONTRACT.value
        ) {
          this.props.form.setFields({
            end_time: {
              errors: [],
            },
          });
        }
      }
      this.props.form.setFieldsValue({
        employment_type: e.target.value,
        weekend_wage: undefined,
        public_holiday_wage: undefined,
        public_holiday_weekend_wage: undefined,
        transport_allowance: undefined,
        meal_allowance: undefined,
        other_allowance: undefined,
      });
    }

    this.setState(prevState => {
      const { staffRequest } = prevState;
      staffRequest[field] = e.target.value;
      if ([fields.EMPLOYMENT_TYPE, fields.WAGE_TYPE].includes(field)) {
        staffRequest.weekend_wage = undefined;
        staffRequest.public_holiday_wage = undefined;
        staffRequest.public_holiday_weekend_wage = undefined;
        staffRequest.transport_allowance = undefined;
        staffRequest.meal_allowance = undefined;
        staffRequest.other_allowance = undefined;
      }
      return { staffRequest };
    });
  };

  handleDateSelect = (date, field) => {
    if (field === 'start_time') {
      this.props.form.setFields({
        end_time: {
          values: [],
          errors: [],
        },
      });
      this.setState(prevState => {
        const { staffRequest } = prevState;
        staffRequest.end_time = undefined;
        staffRequest.start_time = date.toISOString();
        return { staffRequest };
      });
    }
    if (field === 'end_time') {
      this.setState(prevState => {
        const { staffRequest } = prevState;
        staffRequest.end_time = date.toISOString();
        return { staffRequest };
      });
    }
  };

  handleTimeSelect = (time, field) => {
    this.setState(prevState => {
      const { staffRequest } = prevState;
      staffRequest[field] = time;
      return { staffRequest };
    });
  };

  handleWorkingDaysChange = (option, checked) => {
    const { getFieldValue, setFieldsValue } = this.props.form;
    const WorkingDaysFieldLabel = 'working_days';
    const selectedWorkingDays = getFieldValue(WorkingDaysFieldLabel);

    if (checked) {
      selectedWorkingDays.push(option.value);
      setFieldsValue({ [WorkingDaysFieldLabel]: selectedWorkingDays });
      this.setState(prevState => {
        const { staffRequest } = prevState;
        staffRequest.working_days = selectedWorkingDays;
        return { staffRequest };
      });
      return;
    }
    const nextSelectedWorkingDays = selectedWorkingDays.filter(fieldValue => fieldValue !== option.value);
    setFieldsValue({ [WorkingDaysFieldLabel]: nextSelectedWorkingDays });
    this.setState(prevState => {
      const { staffRequest } = prevState;
      staffRequest.working_days = nextSelectedWorkingDays;
      return { staffRequest };
    });
  };

  handleSubmit = async action => {
    const eventName = action;
    const { isEdit, timezone, staffRequest } = this.state;
    const { form, user, fetchClientAndManager, t } = this.props;
    try {
      this.setState({ isSubmitting: true });
      // Submit legal break duration first if we have to
      if (staffRequestUtils.showLegalBreakDuration(form, user)) {
        await clientApi.editClient({
          id: user.clientId,
          legal_break_duration: staffRequest.legal_break_duration,
        });
        await fetchClientAndManager();
      }
      // Payload does not contain legal_break_duration
      const payload = staffRequestUtils.formDataToPayload(staffRequest, timezone, this.props.user?.country?.code);
      let staffRequestResponse = {};
      if (isEdit) {
        payload.id = this.props.match.params.id;
        staffRequestResponse = await staffRequestApi.editStaffRequest(payload);
      } else {
        // Creating a SR
        staffRequestResponse = await staffRequestApi.createStaffRequest(payload);
      }
      if (!staffRequestResponse) {
        message.warning(t('Sorry! An error occured in submission, please contact support'));
        return;
      }
      if (eventName === 'submit') {
        this.submitStaffRequest(staffRequestResponse);
      } else {
        this.reRouteAfterSaving(staffRequestResponse, t);
      }
    } catch (error) {
      Sentry.captureException(error);
      this.setState({ isSubmitting: false });
      message.warning(t('Sorry! An error occured in submission, please contact support'));
    }
  };

  submitStaffRequest = async staffRequest => {
    const response = await staffRequestApi.submitStaffRequest(staffRequest.id);
    if (response) {
      localStorage.setItem(seenModals.SHOW_SUBMITTED_SR, true);
      this.props.fetchClientAndManager();
      this.props.history.push(routes.staffRequests);
    }
  };

  reRouteAfterSubmitting = () => {
    this.props.history.push(routes.staffRequests);
  };

  reRouteAfterSaving = (staffRequest, t) => {
    message.success(t('Staff Request successfully saved'));
    let reRouteTo = `${routes.staffRequests}/${staffRequest.id}`;
    // Draft SRs in Edit mode should back to SR list
    if (staffRequest.status === staffRequestStatuses.DRAFT) {
      reRouteTo = routes.staffRequests;
    }
    this.props.history.push(reRouteTo);
  };

  // Throw error if user selected a field but left it blank
  // TODO: Find correct validate trigger to dropdown fields and add validations
  checkIfTouchedFormHasError = () => {
    const { isFieldTouched, getFieldError } = this.props.form;
    if (isFieldTouched('title')) {
      getFieldError('title');
    }
    if (isFieldTouched('wage_amount')) {
      getFieldError('wage_amount');
    }
  };

  toggleLocationModal = () => {
    this.setState(prevState => {
      return {
        locationModalOpen: !prevState.locationModalOpen,
      };
    });
  };

  newlineToLineBreak = text => {
    return typeof text === 'string'
      ? text.split('\n').map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })
      : '';
  };

  getEmploymentDetailsReviewData = () => {
    const { staffRequest, currencyCode } = this.state;
    const { t, user } = this.props;
    const { timezone } = staffRequest.location?.address.area.city;
    const employmentDate = `${moment.tz(staffRequest.start_time, timezone).format(DATE_WITH_DAY_FORMAT)} - ${
      staffRequest.end_time ? moment.tz(staffRequest.end_time, timezone).format(DATE_WITH_DAY_FORMAT) : t('permanent')
    }`;
    const workingDaysLabel = staffRequestUtils.workingDaysToStringArray(staffRequest?.working_days);
    const employmentDetailsReviewData = [
      {
        label: t('typeOfEmployment'),
        value: staffRequest.employment_type && t(employmentTypes[staffRequest.employment_type.toUpperCase()].label),
      },
      { label: t('employmentPeriod'), value: employmentDate },
      {
        label: t('workingDays'),
        value: workingDaysLabel.map((label, index) =>
          index === workingDaysLabel.length - 1 ? t(label) : `${t(label)}, `,
        ),
      },
      {
        label: `${t('startTime')} - ${t('endTime')}`,
        value: `${moment(staffRequest.begin_time || '00:00', TIME_FORMAT_24_HOUR_WITH_SECONDS).format(
          TIME_FORMAT_24_HOUR,
        )} - ${moment(staffRequest.finish_time || '00:00', TIME_FORMAT_24_HOUR_WITH_SECONDS).format(
          TIME_FORMAT_24_HOUR,
        )}`,
      },
      {
        label: t('breakInfo'),
        value: `${staffRequest.break_duration_in_minutes} ${t('mins')}`,
        subtext: staffRequest.unpaid_break ? t('unpaidBreakType') : t('paidBreakType'),
      },
      { label: t('Wage type'), value: t(textUtils.makeFriendly(staffRequest?.wage_type)) },
      { label: t('Wage amount'), value: `${currencyCode} ${numeral(staffRequest?.wage_amount).format('0,0.00')}` },
    ];

    if (user?.country?.code === countryCodeOptions.SINGAPORE) {
      const otherWageTypes = [
        {
          label: staffRequest?.weekend_includes_friday ? `${t('weekend')} - ${t('includeFriday')}` : t('weekend'),
          value: staffRequest?.weekend_wage || 0,
        },
      ];
      if (staffRequest?.employment_type !== employmentTypes.FULL_TIME.value) {
        otherWageTypes.push({ label: t('publicHoliday'), value: staffRequest?.public_holiday_wage });
        otherWageTypes.push({
          label: t('publicHolidayWeekend'),
          value: staffRequest?.public_holiday_weekend_wage,
        });
      }

      if (otherWageTypes.some(item => item.value > 0)) {
        employmentDetailsReviewData.push({
          label: t('otherWageTypes'),
          value: (
            <Col>
              {otherWageTypes.map(item => (
                <Row key={item.label}>
                  <Text>{`${currencyCode} ${numeral(item.value).format('0,0.00')} (${item.label})`}</Text>
                </Row>
              ))}
            </Col>
          ),
        });
      }
    }

    const allowances = [
      { label: t('Transport allowance'), value: staffRequest?.transport_allowance || 0 },
      { label: t('Meal allowance'), value: staffRequest?.meal_allowance || 0 },
      { label: t('Other allowance'), value: staffRequest?.other_allowance || 0 },
    ];

    if (allowances.some(item => item.value > 0)) {
      employmentDetailsReviewData.push({
        label: t('Allowances'),
        value: (
          <Col>
            {allowances.map(item => (
              <Row key={item.label}>
                <Text>{`${currencyCode} ${numeral(item.value).format('0,0.00')} (${item.label})`}</Text>
              </Row>
            ))}
          </Col>
        ),
      });
    }

    if (staffRequest?.payment_frequency) {
      employmentDetailsReviewData.push({ label: t('Payment frequency'), value: t(staffRequest?.payment_frequency) });
    }

    if (staffRequest?.benefits) {
      employmentDetailsReviewData.push({
        label: t('Benefits'),
        value: <Text>{this.newlineToLineBreak(staffRequest?.benefits)}</Text>,
      });
    }

    if (user?.country?.code === countryCodeOptions.INDONESIA) {
      employmentDetailsReviewData.push({
        label: t('employmentContract'),
        value: t(staffRequest?.contract_type),
      });
    }
    return employmentDetailsReviewData;
  };

  handleSelectForm = form => {
    const { staffRequest } = this.state;
    this.setState({ staffRequest: { ...staffRequest, form_id: form.id }, selectedApplicationForm: form });
  };

  handleSelectTemplate = async value => {
    const { staffRequestTemplates } = this.state;
    const { t } = this.props;
    const template = value ? staffRequestTemplates.find(item => item.id === parseInt(value, 10)) : undefined;
    let selectedApplicationForm;
    this.props.form.setFieldsValue({
      template: template?.template_name,
    });

    if (template?.form_id) {
      try {
        const applicationForm = await formsApi.fetchFormById(template.form_id);
        if (applicationForm) {
          selectedApplicationForm = applicationForm;
        }
      } catch (error) {
        Sentry.captureException(error);
        message.warning(t('Sorry! An error occured in submission, please contact support'));
      }
    }
    this.setState(
      {
        selectedTemplate: template,
        selectedApplicationForm,
        applicationFormToggle: !!selectedApplicationForm,
        firstDayMessageToggle: template?.first_day_message,
        staffRequest: {
          title: template?.title || undefined,
          responsibilities: template?.responsibilities || undefined,
          requirements: template?.requirements || undefined,
          position: template?.position || undefined,
          location: template?.location || undefined,
          wage_type: template?.wage_type || wageTypes.PER_DAY.value,
          wage_amount: template?.wage_amount || undefined,
          employment_type: template?.employment_type || employmentTypes.FULL_TIME.value,
          transport_allowance: template?.transport_allowance || undefined,
          meal_allowance: template?.meal_allowance || undefined,
          other_allowance: template?.other_allowance || undefined,
          payment_frequency: template?.payment_frequency || undefined,
          form_id: template?.form_id || undefined,
          first_day_message: template?.first_day_message || undefined,
          benefits: template?.benefits || undefined,
          start_time: moment(),
          require_experience: template?.require_experience,
          staff_required: 1,
          working_days: [0, 1, 2, 3, 4],
          begin_time: undefined,
          finish_time: undefined,
          unpaid_break: true,
          break_duration_in_minutes: 0,
          interview_type: undefined,
          contract_type: template?.contract_type,
          weekend_wage: template?.weekend_wage || undefined,
          public_holiday_wage: template?.public_holiday_wage || undefined,
          weekend_includes_friday: template?.weekend_includes_friday || undefined,
          public_holiday_weekend_wage: template?.public_holiday_weekend_wage || undefined,
          vip_workers_only: template?.vip_workers_only || false,
        },
        timezone: template.location?.address.area.city.timezone,
      },
      () => this.getAndSetVipWorkerCount(template?.position),
    );
  };

  handleRefresh = () => {
    const { t } = this.props;
    message.success(t('formListRefreshed'));
  };

  handleCreateOrUpdateTemplate = async action => {
    const { staffRequest, selectedTemplate } = this.state;
    const { t, user } = this.props;
    const payload = staffRequestUtils.formStaffRequestTemplatePayload(staffRequest, user.clientId, 'submit');

    try {
      let response;
      if (action === 'submit') {
        response = await staffRequestTemplateApi.submitStaffRequestTemplate(payload);
      } else if (action === 'save') {
        response = await staffRequestTemplateApi.editStaffRequestTemplate({
          id: selectedTemplate.id,
          ...payload,
        });
      }

      if (response) {
        this.handleSubmit('submit');
      }
    } catch (error) {
      Sentry.captureException(error);
      this.setState({ isSubmitting: false });
      message.warning(t('duplicateStaffRequestTemplate'));
    }
  };

  shouldUpdateTemplate = () => {
    // checks if the template selected has been edited/modified

    const { staffRequest, selectedTemplate } = this.state;
    const keysToOmit = ['template_name', 'status', 'version'];

    // pick only the keys that can be checked, remove unused keys
    const staffRequestTemplateSelected = omit(
      staffRequestUtils.formStaffRequestTemplatePayload(selectedTemplate),
      keysToOmit,
    );

    const difference = Object.keys(staffRequestTemplateSelected).filter(
      key =>
        // eslint-disable-next-line eqeqeq
        staffRequestTemplateSelected[key] != (staffRequest[`${key}_id`] || staffRequest[key]?.id || staffRequest[key]),
    );

    return difference?.length > 0;
  };

  handleUpdateTemplate = async () => {
    const { selectedTemplate } = this.state;
    this.setState(
      prevState => ({
        staffRequest: {
          ...prevState.staffRequest,
          template_name: selectedTemplate?.template_name,
        },
      }),
      () => this.handleCreateOrUpdateTemplate('save'),
    );
  };

  render() {
    if (this.state.isLoading || this.state.isSubmitting) {
      return <LoadingSpinner />;
    }
    const {
      staffRequest,
      currencyCode,
      isEdit,
      isDuplicate,
      locationModalOpen,
      currentStep,
      timezone,
      firstDayMessageToggle,
      applicationFormToggle,
      selectedApplicationForm,
      staffRequestTemplates,
      selectedTemplate,
      saveAsJobTemplateModalOpen,
      updateJobTemplateModalOpen,
      hasExistingContractType,
      vipWorkerCount,
    } = this.state;
    const { t, positions, user, form, fetchClientAndManager, vipWorkerJobPosting } = this.props;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const isWorkmateAccount = staffRequestUtils.isWorkmateAccount(user?.username);

    if (user.status === userStatuses.LEAD) {
      return <ErrorPage title="Error" subTitle={t('Sorry! There is an error loading the page')} />;
    }

    const wageTypeOptions = [
      { disabled: false, value: wageTypes.PER_HOUR.value, label: t('hourly') },
      { disabled: false, value: wageTypes.PER_DAY.value, label: t('daily') },
      { disabled: false, value: wageTypes.PER_MONTH.value, label: t('monthly') },
    ];
    const paymentFrequencyOptions = [
      { disabled: false, value: paymentFrequency.WEEKLY.key, label: t('weekly') },
      { disabled: false, value: paymentFrequency.BIWEEKLY.key, label: t('biWeekly') },
      { disabled: false, value: paymentFrequency.MONTHLY.key, label: t('monthly') },
    ];
    const interviewOptions = [
      {
        disabled: false,
        value: interviewOptionKeys.No.value,
        label: t(interviewOptionKeys.No.transKey),
      },
      {
        disabled: false,
        value: interviewOptionKeys.Yes.value,
        label: t(interviewOptionKeys.Yes.transKey),
      },
    ];

    let employmentTypeOptions = staffRequestUtils.getEmploymentTypeOptions(t, user);
    // hide gig employment type
    employmentTypeOptions = employmentTypeOptions.filter(
      employmentTypeOption => employmentTypeOption.value !== employmentTypes.GIG.value,
    );

    this.checkIfTouchedFormHasError();
    const showLegalBreakDuration = staffRequestUtils.showLegalBreakDuration(form, user);

    const endDateOptional = [employmentTypes.FULL_TIME.value, employmentTypes.PART_TIME.value].includes(
      staffRequest.employment_type,
    );

    const translatedPosition =
      staffRequest && positions ? positions.find(pos => pos?.id === staffRequest?.position?.id) : undefined;

    const isDraftOrPendingReview = [staffRequestStatuses.DRAFT, staffRequestStatuses.PENDING_REVIEW].includes(
      staffRequest?.status,
    );

    return (
      <Form hideRequiredMark layout="vertical">
        {/* Header */}
        <Row style={{ flex: 1, flexDirection: 'column', marginBottom: '36px' }}>
          <Title level={2} style={{ marginBottom: '8px' }}>
            {isEdit ? t('Edit job') : t('postAJob')}
          </Title>
        </Row>
        {/* STEPS */}
        <Row>
          <Col xs={24}>
            <Steps
              current={currentStep}
              className="v2-steps-wrapper"
              onChange={newTabNumber => {
                form.validateFieldsAndScroll(async err => {
                  if (!err) {
                    this.setState({ currentStep: newTabNumber });
                  }
                });
              }}
              responsive
              style={{ marginBottom: '44px' }}
            >
              <Step disabled title={t('jobDetails')} />
              <Step disabled title={t('employmentDetails')} />
              <Step disabled title={t('applicationForm')} />
              <Step disabled title={t('firstDayMessage')} />
              <Step disabled title={t('reviewSubmit')} />
            </Steps>
          </Col>
        </Row>
        <section style={{ marginBottom: '48px', display: currentStep !== 0 && 'none' }}>
          <Row>
            {isEdit && (
              <Row style={{ marginBottom: '32px' }}>
                <Col sm={24} md={20}>
                  <Alert message={t('editSRInfoMessage')} type="info" showIcon />
                </Col>
              </Row>
            )}
            <Col xs={24} md={8}>
              <Text strong style={{ fontSize: '16px' }}>
                {t('templates')}
              </Text>
              <Row style={{ marginTop: '16px' }}>
                <Col xs={24}>
                  <Item label={t('selectTemplateLabel')} style={{ marginBottom: '8px' }}>
                    {getFieldDecorator('template', {
                      rules: [],
                      initialValue: undefined,
                    })(
                      <AutoComplete
                        style={{ width: '100%' }}
                        options={staffRequestTemplates?.map(template => ({
                          label: template?.template_name,
                          value: `${template?.id}`,
                        }))}
                        filterOption
                        onSelect={this.handleSelectTemplate}
                        onClear={this.handleSelectTemplate}
                        notFoundContent={t('searchNothingFoundMsg')}
                        allowClear
                      >
                        <Input
                          placeholder={t('autoCompletePlaceholder')}
                          suffix={<DownOutlined className="sr-autocomplete-caret" />}
                        />
                      </AutoComplete>,
                    )}
                  </Item>
                </Col>
              </Row>
              <Text strong style={{ fontSize: '16px' }}>
                {t('basicsTitle')}
              </Text>
              <Row style={{ marginTop: '16px' }}>
                <Col xs={24}>
                  <Item label={t('typeOfWorker')} style={{ marginBottom: '8px' }}>
                    {getFieldDecorator('position', {
                      rules: [
                        { required: true, message: t('Please select a position') },
                        {
                          validator(rule, value, callback) {
                            if (value && !positions.find(positionValue => positionValue.name === value)) {
                              callback(t('chooseFromListError'));
                            }
                            callback();
                          },
                        },
                      ],
                      initialValue: translatedPosition?.name || undefined,
                    })(
                      <AutoComplete
                        style={{ width: '100%' }}
                        options={positions.map(position => ({ value: position.name }))}
                        filterOption
                        onSelect={selection => this.handleAutoSelect(selection, 'position')}
                        notFoundContent={t('searchNothingFoundMsg')}
                        allowClear
                        onClear={() => this.handleAutoSelect(undefined, 'position')}
                      >
                        <Input
                          placeholder={t('autoCompletePlaceholder')}
                          suffix={<DownOutlined className="sr-autocomplete-caret" />}
                        />
                      </AutoComplete>,
                    )}
                  </Item>
                </Col>
              </Row>
              <Row>
                <Text style={{ fontSize: '14px' }}>{t('howManyAndWhere')}</Text>
              </Row>
              <Row>
                <Col xs={8} md={6}>
                  <Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('staff_required', {
                      rules: [{ required: true, message: t('Number of staff must be at least 1') }],
                      initialValue: staffRequest?.staff_required || 1,
                    })(
                      <InputNumber
                        min={1}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        style={{ width: '100%' }}
                        onChange={debounce(
                          selectedInput => this.handleSelectInput(selectedInput, 'staff_required'),
                          numberInputDelay,
                        )}
                      />,
                    )}
                  </Item>
                </Col>
                <Text style={{ fontSize: '14px', marginLeft: '10px', marginRight: '10px', marginTop: '6px' }}>
                  {t('at')}
                </Text>
                <Col xs={24} md={16} style={{ marginBottom: 0 }}>
                  <Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator('location', {
                      rules: [
                        { required: true, message: t('Please select a location') },
                        {
                          validator(rule, value, callback) {
                            if (value && !user.locations.find(({ name }) => name === value)) {
                              callback(t('chooseFromListError'));
                            }
                            callback();
                          },
                        },
                      ],
                      initialValue: staffRequest?.location?.name || undefined,
                    })(
                      <AutoComplete
                        style={{ width: '100%' }}
                        options={user.locations.map(location => ({ value: location.name }))}
                        filterOption
                        onSelect={selection => this.handleAutoSelect(selection, 'location')}
                        disabled={user.locations.length === 0}
                        notFoundContent={t('searchNothingFoundMsg')}
                        suffixIcon={<DownOutlined />}
                      >
                        <Input
                          placeholder={
                            user.locations.length > 0
                              ? t('autoCompletePlaceholder')
                              : t('Please add a new location first')
                          }
                          suffix={<DownOutlined className="sr-autocomplete-caret" />}
                        />
                      </AutoComplete>,
                    )}
                  </Item>
                  <Row style={{ marginBottom: '16px' }}>
                    {this.props.user.role === roles.ADMIN && (
                      <Button
                        type="link"
                        icon={<PlusCircleOutlined />}
                        onClick={this.toggleLocationModal}
                        style={{ padding: '0px' }}
                      >
                        <span style={{ textDecoration: 'underline' }}>{t('Create new location')}</span>
                      </Button>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            {user?.country?.code === countryCodeOptions.SINGAPORE && (
              <Col
                xs={24}
                sm={24}
                md={{ span: 6, offset: 10 }}
                style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%', marginBottom: 0 }}
              >
                <Text strong>{t('tafepGuideLineTitle')}</Text>
                <ul style={{ paddingLeft: 0, marginBottom: 20, listStyle: 'none' }}>
                  <li>{t('tafepGuideLineDescription')}</li>
                </ul>
                <a
                  href={TAFEP_GUIDELINES_LINK}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  {t('tafepGuideLineCTA')}
                </a>
              </Col>
            )}
          </Row>
          <Row style={{ marginTop: '16px' }}>
            <Col xs={24} md={colSpan}>
              <Item label={t('interviewTypeLabel')} style={{ marginBottom: '8px' }}>
                {getFieldDecorator('client_interview_required', {
                  ...(!isEdit && staffRequest?.status !== staffRequestStatuses.DRAFT
                    ? { rules: [{ required: true, message: t('clientInterviewRequiredErrorMessage') }] }
                    : {}),
                  initialValue: [true, false].includes(staffRequest?.client_interview_required)
                    ? staffRequest?.client_interview_required
                    : undefined,
                  getFieldValue,
                })(
                  <Radio.Group
                    disabled={isEdit && staffRequest?.status !== staffRequestStatuses.DRAFT}
                    className="v2-radio-wrapper"
                    style={{ width: '100%' }}
                    onChange={e => {
                      this.handleTypeChange(e, 'client_interview_required');
                      // reset auto_hire check value to false if client interview is not required
                      if (e.target.value === interviewOptionKeys.No.value) {
                        this.handleAutohireChange(false);
                      }
                    }}
                  >
                    {interviewOptions.map((option, index) => (
                      <Row key={index} style={{ width: '100%', marginBottom: 10 }}>
                        <Col xs={2}>
                          <Radio value={option.value}>
                            <Typography.Text style={{ display: 'block', color: colors.primaryText }}>
                              {t(option.label)}
                            </Typography.Text>
                          </Radio>
                        </Col>
                        {option.label === t('interviewOptionYes') && (
                          <Checkbox
                            checked={staffRequest?.auto_hire}
                            onChange={e => this.handleAutohireChange(e.target.checked)}
                            disabled={getFieldValue('client_interview_required') !== interviewOptionKeys.Yes.value}
                          >
                            {t('interviewRequiredByPassDescription')}
                          </Checkbox>
                        )}
                      </Row>
                    ))}
                  </Radio.Group>,
                )}
                {!isEdit && getFieldValue('client_interview_required') !== undefined && (
                  <Alert
                    type="info"
                    message={t('interviewAlertMessage')}
                    showIcon
                    style={{ maxWidth: 520, marginTop: 10 }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          {vipWorkerJobPosting && (
            <Row style={{ marginTop: '16px' }}>
              <Col xs={24} md={colSpan}>
                <Item label={t('vipJobLabel')} style={{ marginBottom: '8px' }}>
                  {getFieldDecorator('vip_workers_only', {
                    initialValue: (staffRequest && staffRequest.vip_workers_only) || false,
                  })(
                    <Radio.Group
                      disabled={isEdit && staffRequest?.status !== staffRequestStatuses.DRAFT}
                      className="v2-radio-wrapper"
                      style={{ width: '100%' }}
                      onChange={e => {
                        this.handleTypeChange(e, 'vip_workers_only');
                      }}
                    >
                      {[
                        { value: false, label: t('openToAnyone') },
                        { value: true, label: t('vipWorkersOnly') },
                      ].map((option, index) => (
                        <Row key={index} style={{ width: '100%', marginBottom: 10 }}>
                          <Col>
                            <Radio value={option.value}>
                              <Typography.Text style={{ display: 'block', color: colors.primaryText }}>
                                {t(option.label)}
                              </Typography.Text>
                            </Radio>
                          </Col>
                        </Row>
                      ))}
                    </Radio.Group>,
                  )}
                </Item>
                {fields.vip_workers_only && vipWorkerCount === 0 && (
                  <Typography.Text
                    style={{
                      display: 'block',
                      color: colors.functionalError,
                      marginTop: '-15px',
                      marginBottom: '10px',
                    }}
                  >
                    {t('noVipWorkerError')}
                  </Typography.Text>
                )}
              </Col>
            </Row>
          )}

          <Text strong style={{ fontSize: '16px' }}>
            {t('Job description')}
          </Text>
          <Row style={{ marginBottom: '16px', marginTop: '16px' }}>
            <Col sm={24} md={10}>
              <Item label={t('jobPostTitle')} style={{ marginBottom: 0 }}>
                {getFieldDecorator('title', {
                  validate: [{ trigger: 'onBlur', rules: [{ required: true, message: t('Please input a title') }] }],
                  initialValue: staffRequest?.title || undefined,
                })(
                  <TextArea
                    autoSize={{ maxRows: 1 }}
                    style={{ width: '100%' }}
                    placeholder={t('jobPostTitlePlaceholder')}
                    onChange={debounce(text => this.handleAddSrDetails(text, 'title'), textInputDelay)}
                  />,
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" style={{ marginBottom: '16px' }}>
            <Col sm={24} md={colSpan}>
              <Item label={t('responsibilitiesTitle')} style={{ marginBottom: 0 }}>
                {getFieldDecorator('responsibilities', {
                  validate: [
                    {
                      trigger: 'onBlur',
                      rules: [
                        {
                          required: true,
                          min: 50,
                          message: t('minimumCharacterError'),
                        },
                      ],
                    },
                  ],
                  initialValue: staffRequest?.responsibilities || undefined,
                })(
                  <TextArea
                    autoSize={{ minRows: 12, maxRows: 12 }}
                    style={{ width: '100%', marginBottom: 0 }}
                    placeholder={t('responsibilitiesPlaceholder')}
                    onChange={debounce(text => this.handleAddSrDetails(text, 'responsibilities'), textInputDelay)}
                  />,
                )}
              </Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={{ span: 6, offset: 2 }}
              style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%' }}
            >
              <Text strong>{t('hint1Title')}</Text>
              <ul style={{ paddingLeft: '24px' }}>
                <li>{t('hint1Point1')}</li>
                <li>{t('hint1Point2')}</li>
                <li>{t('hint1Point3')}</li>
                <li>{t('hint1Point4')}</li>
                <li>{t('hint1Point5')}</li>
                <li>{t('hint1Point6')}</li>
              </ul>
            </Col>
          </Row>
          <Row type="flex" style={{ marginBottom: '16px' }}>
            <Col xs={24} sm={24} md={colSpan}>
              <Item label={t('requirementsTitle')} style={{ marginBottom: 0 }}>
                {getFieldDecorator('requirements', {
                  validate: [
                    {
                      trigger: 'onBlur',
                      rules: [
                        {
                          required: true,
                          min: 50,
                          message: t('minimumCharacterError'),
                        },
                      ],
                    },
                  ],
                  initialValue: staffRequest?.requirements || undefined,
                })(
                  <TextArea
                    autoSize={{ minRows: 12, maxRows: 12 }}
                    style={{ width: '100%', marginBottom: 0 }}
                    placeholder={t('requirementsPlaceholder')}
                    onChange={debounce(text => this.handleAddSrDetails(text, 'requirements'), textInputDelay)}
                  />,
                )}
              </Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={{ span: 6, offset: 2 }}
              style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%' }}
            >
              <Text strong>{t('hint2Title')}</Text>
              <ul style={{ paddingLeft: '24px' }}>
                <li>{t('hint2Point1')}</li>
                <li>{t('hint2Point2')}</li>
                <li>{t('hint2Point3')}</li>
                <li>{t('hint2Point4')}</li>
                <li>{t('hint2Point5')}</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col span={colSpan}>
              <Item style={{ margin: 0 }}>
                {getFieldDecorator('require_experience', {
                  initialValue: staffRequest?.require_experience,
                })(
                  <Checkbox
                    onChange={e => {
                      this.handleAddRequireWorkExp(e.target.checked);
                    }}
                    checked={staffRequest?.require_experience}
                  >
                    {t('relevantWorkExperienceNeeded')}
                  </Checkbox>,
                )}
              </Item>
            </Col>
          </Row>
        </section>
        {currentStep === 1 && (
          <section style={{ marginBottom: '48px' }}>
            <Row style={{ marginBottom: '16px' }}>
              <Text strong style={{ fontSize: '16px' }}>
                {t('Employment')}
              </Text>
            </Row>
            <Row>
              <Col>
                <Item label={t('typeOfEmployment')}>
                  {getFieldDecorator(fields.EMPLOYMENT_TYPE, {
                    initialValue: staffRequest?.employment_type || employmentTypes.FULL_TIME.value,
                  })(
                    <Radio.Group
                      className="v2-radio-wrapper"
                      style={{ width: '100%' }}
                      onChange={e => this.handleTypeChange(e, fields.EMPLOYMENT_TYPE)}
                    >
                      {employmentTypeOptions.map((option, index) => (
                        <Radio key={index} value={option.value}>
                          {option.label}
                        </Radio>
                      ))}
                    </Radio.Group>,
                  )}
                </Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: '8px' }}>
              <Text style={{ fontSize: '14px' }}>{t('employmentPeriod')}</Text>
            </Row>
            <Row>
              <Col xs={24} md={6}>
                <Item>
                  {getFieldDecorator('start_time', {
                    initialValue:
                      (staffRequest?.start_time && moment.tz(staffRequest.start_time, timezone)) || moment(),
                  })(
                    <DatePicker
                      allowClear={false}
                      format="DD-MM-YYYY"
                      disabledDate={current => current < moment().endOf('day')}
                      onSelect={date => this.handleDateSelect(date, 'start_time')}
                      onChange={date => this.handleDateSelect(date, 'start_time')}
                      style={{ width: '100%' }}
                    />,
                  )}
                </Item>
              </Col>
              <Text style={{ fontSize: '14px', margin: '6px 8px' }}>{t('to')}</Text>
              <Col xs={24} md={6}>
                <Item style={{ paddingBottom: 0 }}>
                  {getFieldDecorator('end_time', {
                    rules: [
                      {
                        validator: (rule, value, callback) => {
                          if (value && getFieldValue('start_time')) {
                            const daysApart = value.diff(getFieldValue('start_time'), 'days');

                            if (daysApart > 365) {
                              // Set error on form
                              callback(t('End date cannot be more than a year from start date.'));
                            }
                            if (daysApart < 0) {
                              callback(t('End date cannot be earlier than start date.'));
                            }
                          }
                          // Have to be called to validate the rest of the fields.
                          callback();
                        },
                      },
                      { required: !endDateOptional, message: t('Please select an end date') },
                    ],
                    initialValue: (staffRequest?.end_time && moment.tz(staffRequest.end_time, timezone)) || undefined,
                  })(
                    <DatePicker
                      format="DD-MM-YYYY"
                      disabledDate={current =>
                        current < moment().endOf('day') || current < moment(getFieldValue('start_time'))
                      }
                      onSelect={date => this.handleDateSelect(date, 'end_time')}
                      onChange={date => this.handleDateSelect(date, 'end_time')}
                      style={{ width: '100%' }}
                      allowClear
                    />,
                  )}
                </Item>
              </Col>
            </Row>
            {user?.country?.code === countryCodeOptions.SINGAPORE && (
              <>
                <Row>
                  <Col span={24}>
                    <Item label={t('workingDays')}>
                      {getFieldDecorator('working_days', {
                        initialValue: staffRequest?.working_days || [],
                        rules: [
                          {
                            type: 'array',
                            min: 1,
                            required: true,
                            message: t('fieldIsRequired'),
                          },
                        ],
                      })(
                        <Checkbox.Group style={{ width: '100%' }}>
                          <Row type="flex" className="v2-checkable-tag-wrapper">
                            {recurrenceOptions.map((option, index) => {
                              const WorkingDaysFieldLabel = 'working_days';
                              const selectedWorkingDays = getFieldValue(WorkingDaysFieldLabel);
                              return (
                                <Tag.CheckableTag
                                  key={index}
                                  checked={(selectedWorkingDays || []).includes(option.value)}
                                  onChange={checked => this.handleWorkingDaysChange(option, checked)}
                                  style={{
                                    padding: '5px 8px',
                                    marginBottom: '5px',
                                    width: '54px',
                                    textAlign: 'center',
                                    border: '1px solid #D9D9D9',
                                  }}
                                >
                                  {t(option.label)}
                                </Tag.CheckableTag>
                              );
                            })}
                          </Row>
                        </Checkbox.Group>,
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={8} style={{ marginRight: 24 }}>
                    <Item label={t('startTime')}>
                      {getFieldDecorator('begin_time', {
                        rules: [{ required: true, message: t('fieldIsRequired') }],
                        initialValue: staffRequest?.begin_time
                          ? moment(staffRequest.begin_time, TIME_FORMAT_24_HOUR)
                          : null,
                      })(
                        <TimePicker
                          minuteStep={15}
                          format={TIME_FORMAT_24_HOUR}
                          style={{ width: '100%' }}
                          onSelect={time => this.handleTimeSelect(time, 'begin_time')}
                          onChange={time => this.handleTimeSelect(time, 'begin_time')}
                        />,
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Item label={t('endTime')}>
                      {getFieldDecorator('finish_time', {
                        rules: [{ required: true, message: t('fieldIsRequired') }],
                        initialValue: staffRequest?.finish_time
                          ? moment(staffRequest.finish_time, TIME_FORMAT_24_HOUR)
                          : null,
                      })(
                        <TimePicker
                          minuteStep={15}
                          format={TIME_FORMAT_24_HOUR}
                          style={{ width: '100%' }}
                          onSelect={time => this.handleTimeSelect(time, 'finish_time')}
                          onChange={time => this.handleTimeSelect(time, 'finish_time')}
                        />,
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={5} style={{ marginRight: 24 }}>
                    <Item label={t('totalBreakDuration')}>
                      {getFieldDecorator('break_duration_in_minutes', {
                        rules: [{ required: true, message: t('fieldIsRequired') }],
                        initialValue: staffRequest?.break_duration_in_minutes || 0,
                      })(
                        <Select
                          style={{ width: '100%' }}
                          options={formUtils.getBreakDurationChoices(t)}
                          onChange={selectedInput => this.handleSelectInput(selectedInput, 'break_duration_in_minutes')}
                        />,
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={5} style={{ marginRight: 24 }}>
                    <Item label={t('breakType')}>
                      {getFieldDecorator('unpaid_break', {
                        initialValue: staffRequest?.unpaid_break,
                      })(
                        <Radio.Group
                          className="v2-radio-wrapper"
                          style={{ width: '100%' }}
                          onChange={e => this.handleTypeChange(e, 'unpaid_break')}
                        >
                          {[
                            { value: true, label: t('unpaidBreakType') },
                            { value: false, label: t('paidBreakType') },
                          ].map((option, index) => (
                            <Radio key={index} value={option.value}>
                              {option.label}
                            </Radio>
                          ))}
                        </Radio.Group>,
                      )}
                    </Item>
                  </Col>
                </Row>
              </>
            )}
            {showLegalBreakDuration && (
              <Row>
                <Item
                  label={t('breakDaysPkhl')}
                  style={{ marginBottom: '8px' }}
                  extra={
                    // Warning for 0 break day is not in validator because it should not be regarded as field error
                    getFieldValue('legal_break_duration') === 0 && (
                      <span style={{ color: colors.red }}>
                        {t(
                          'We note you have selected 0 days, as per PKHL regulation you should have at least 1 day. You acknowledge that choosing 0 days is at your discretion',
                        )}
                      </span>
                    )
                  }
                >
                  {getFieldDecorator('legal_break_duration', {
                    rules: [{ required: true, message: t('This field is required.') }],
                    initialValue: staffRequest?.legal_break_duration || 1,
                  })(
                    <InputNumber
                      style={{ width: '20%' }}
                      min={1}
                      onChange={debounce(
                        selectedInput => this.handleSelectInput(selectedInput, 'legal_break_duration'),
                        numberInputDelay,
                      )}
                    />,
                  )}
                </Item>
              </Row>
            )}
            <Col style={{ marginTop: '24px' }}>
              <Text strong style={{ fontSize: '16px' }}>
                {t('wageTitle')}
              </Text>
              <Row style={{ marginTop: '24px' }}>
                <Text style={{ fontSize: 14, color: colors.black }}>{t('wageType')}</Text>
              </Row>
              <Row>
                <Item style={{ marginBottom: 0, paddingTop: 8 }}>
                  {getFieldDecorator(fields.WAGE_TYPE, {
                    initialValue: staffRequest?.wage_type || wageTypes.PER_DAY.value,
                  })(
                    <Radio.Group
                      className="v2-radio-wrapper"
                      style={{ width: '100%' }}
                      onChange={e => this.handleTypeChange(e, fields.WAGE_TYPE)}
                    >
                      {wageTypeOptions.map(({ value, label, disabled }, index) => (
                        <Radio key={index} value={value} disabled={disabled}>
                          {label}
                        </Radio>
                      ))}
                    </Radio.Group>,
                  )}
                </Item>
              </Row>
            </Col>

            <Row>
              <Col sm={24} md={colSpan}>
                <Row style={{ marginTop: '24px' }}>
                  <Text style={{ fontSize: 14, color: colors.black }}>{t('wageAmount')}</Text>
                </Row>
                <Row style={{ paddingTop: 8, alignItems: 'center' }}>
                  <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                    <Text style={{ marginRight: 10 }}>{t('baseWage')}</Text>
                    <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                      {getFieldDecorator('wage_amount', {
                        rules: [{ required: true, message: t('Please input a wage amount') }],
                        initialValue: staffRequest?.wage_amount || undefined,
                      })(
                        <InputNumber
                          min={0}
                          formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                          onChange={debounce(
                            selectedInput => this.handleSelectInput(selectedInput, 'wage_amount'),
                            numberInputDelay,
                          )}
                          style={{ width: '100%' }}
                        />,
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row style={{ marginTop: '12px' }}>
                  <Col sm={24} md={colSpan}>
                    <Collapse ghost>
                      <Panel
                        header={
                          user?.country?.code === countryCodeOptions.SINGAPORE
                            ? t('otherWageTypeAllowancesPayFrequency')
                            : t('allowancesPayFrequency')
                        }
                        headerClass="advanced-settings-header"
                        className="advanced-settings-content"
                      >
                        {user?.country?.code === countryCodeOptions.SINGAPORE && (
                          <Row style={{ marginBottom: 32 }}>
                            <Col xs={24} md={24}>
                              <Row>
                                <Col>
                                  <Text strong>{`${t('otherWageTypes')}`}</Text>
                                </Col>
                              </Row>
                              <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                                <Col
                                  span={12}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Text style={{ width: 150 }}>{t('weekend')}</Text>
                                  <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                                    {getFieldDecorator('weekend_wage', {
                                      initialValue: staffRequest?.weekend_wage || undefined,
                                    })(
                                      <InputNumber
                                        min={0}
                                        formatter={value =>
                                          `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        }
                                        parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                        onChange={debounce(
                                          selectedInput => this.handleSelectInput(selectedInput, 'weekend_wage'),
                                          numberInputDelay,
                                        )}
                                        style={{ width: '100%' }}
                                      />,
                                    )}
                                  </Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 12 }}>
                                  <Checkbox
                                    disabled={!staffRequest?.weekend_wage}
                                    checked={staffRequest?.weekend_includes_friday}
                                    onChange={e => this.handleSelectInput(e.target.checked, 'weekend_includes_friday')}
                                  >
                                    {t('includeFriday')}
                                  </Checkbox>
                                </Col>
                              </Row>
                              <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                                <Col
                                  span={12}
                                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                  <Text style={{ width: 150 }}>{t('publicHoliday')}</Text>
                                  <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                                    {getFieldDecorator('public_holiday_wage', {
                                      initialValue: staffRequest?.public_holiday_wage,
                                    })(
                                      <InputNumber
                                        min={0}
                                        disabled={staffRequest.employment_type === employmentTypes.FULL_TIME.value}
                                        formatter={value =>
                                          `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        }
                                        parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                        onChange={debounce(
                                          selectedInput => this.handleSelectInput(selectedInput, 'public_holiday_wage'),
                                          numberInputDelay,
                                        )}
                                        style={{ width: '100%' }}
                                      />,
                                    )}
                                  </Item>
                                </Col>
                              </Row>
                              <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                                <Col
                                  span={12}
                                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                >
                                  <Text style={{ width: 150 }}>{t('publicHolidayWeekend')}</Text>
                                  <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                                    {getFieldDecorator('public_holiday_weekend_wage', {
                                      initialValue: staffRequest?.public_holiday_weekend_wage,
                                    })(
                                      <InputNumber
                                        min={0}
                                        disabled={staffRequest.employment_type === employmentTypes.FULL_TIME.value}
                                        formatter={value =>
                                          `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        }
                                        parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                        onChange={debounce(
                                          selectedInput =>
                                            this.handleSelectInput(selectedInput, 'public_holiday_weekend_wage'),
                                          numberInputDelay,
                                        )}
                                        style={{ width: '100%' }}
                                      />,
                                    )}
                                  </Item>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col xs={24} md={12}>
                            <Row>
                              <Col>
                                <Text strong>{`${t('allowances')}`}</Text>
                              </Col>
                            </Row>
                            <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                              <Col
                                span={24}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              >
                                <Text style={{ width: 150 }}>{t('transportAllowance')}</Text>
                                <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                                  {getFieldDecorator('transport_allowance', {
                                    initialValue: staffRequest?.transport_allowance || undefined,
                                  })(
                                    <InputNumber
                                      min={0}
                                      disabled={getFieldValue('wage_type') === wageTypes.PER_MONTH.value}
                                      formatter={value =>
                                        `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      }
                                      parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                      onChange={debounce(
                                        selectedInput => this.handleSelectInput(selectedInput, 'transport_allowance'),
                                        numberInputDelay,
                                      )}
                                      style={{ width: '100%' }}
                                    />,
                                  )}
                                </Item>
                              </Col>
                            </Row>
                            <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                              <Col
                                span={24}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              >
                                <Text style={{ width: 150 }}>{t('mealAllowance')}</Text>
                                <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                                  {getFieldDecorator('meal_allowance', {
                                    initialValue: staffRequest?.meal_allowance || undefined,
                                  })(
                                    <InputNumber
                                      min={0}
                                      disabled={getFieldValue('wage_type') === wageTypes.PER_MONTH.value}
                                      formatter={value =>
                                        `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      }
                                      parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                      onChange={debounce(
                                        selectedInput => this.handleSelectInput(selectedInput, 'meal_allowance'),
                                        numberInputDelay,
                                      )}
                                      style={{ width: '100%' }}
                                    />,
                                  )}
                                </Item>
                              </Col>
                            </Row>
                            <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                              <Col
                                span={24}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                              >
                                <Text style={{ width: 150 }}>{t('otherAllowance')}</Text>
                                <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                                  {getFieldDecorator('other_allowance', {
                                    initialValue: staffRequest?.other_allowance || undefined,
                                  })(
                                    <InputNumber
                                      min={0}
                                      disabled={getFieldValue('wage_type') === wageTypes.PER_MONTH.value}
                                      formatter={value =>
                                        `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                      }
                                      parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                      onChange={debounce(
                                        selectedInput => this.handleSelectInput(selectedInput, 'other_allowance'),
                                        numberInputDelay,
                                      )}
                                      style={{ width: '100%' }}
                                    />,
                                  )}
                                </Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: 32 }}>
                          <Col>
                            <Text strong>{`${t('paymentFrequency')}`}</Text>
                          </Col>
                        </Row>
                        <Row type="flex">
                          <Col span={10}>
                            <Item style={{ paddingTop: 8 }}>
                              {getFieldDecorator('payment_frequency', {
                                initialValue: staffRequest?.payment_frequency || undefined,
                              })(
                                <Select
                                  allowClear
                                  placeholder={t('selectOne')}
                                  style={{ width: '100%' }}
                                  onChange={selectedInput => this.handleSelectInput(selectedInput, 'payment_frequency')}
                                >
                                  {paymentFrequencyOptions.map(({ value, label, disabled }, index) => (
                                    <Option key={index} value={value} disabled={disabled}>
                                      {label}
                                    </Option>
                                  ))}
                                </Select>,
                              )}
                            </Item>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
              {user?.country?.code === countryCodeOptions.SINGAPORE && (
                <Col
                  xs={24}
                  sm={24}
                  md={{ span: 6, offset: 2 }}
                  style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%' }}
                >
                  <Text strong>{t('otherWagesHintHeader')}</Text>
                  <ul>
                    <li>{t('otherWagesHint1')}</li>
                    <li>{t('otherWagesHint2')}</li>
                  </ul>
                  <Trans i18nKey="otherWagesHintSubheader">
                    <Text>
                      You can set your own fixed amounts for these days under
                      <Text style={{ fontWeight: 'bold' }}> Other wage types</Text>
                    </Text>
                  </Trans>
                </Col>
              )}
            </Row>

            <Row style={{ marginTop: '12px' }}>
              <Col sm={24} md={colSpan}>
                <Item label={t('benefitsTitle')} style={{ marginBottom: 0 }}>
                  {getFieldDecorator('benefits', {
                    initialValue: staffRequest?.benefits || undefined,
                  })(
                    <TextArea
                      autoSize={{ minRows: 6, maxRows: 6 }}
                      style={{ width: '100%', marginBottom: 0 }}
                      placeholder={t('benefitsPlaceholder')}
                      onChange={debounce(text => this.handleAddSrDetails(text, 'benefits'), textInputDelay)}
                    />,
                  )}
                </Item>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={{ span: 6, offset: 2 }}
                style={{ backgroundColor: colors.whiteBackground, padding: '16px', height: '80%' }}
              >
                <Text strong>{t('hint3Title')}</Text>
                <ul>
                  <li>{t('hint3Point1')}</li>
                  <li>{t('hint3Point2')}</li>
                </ul>
              </Col>
            </Row>
            {user?.country?.code === countryCodeOptions.INDONESIA && (
              <Row style={{ marginTop: '12px' }}>
                <Col>
                  <Item label={t('employmentContract')}>
                    {getFieldDecorator('contract_type', {
                      initialValue: staffRequest?.contract_type,
                      rules: [{ required: true, message: t('selectEmploymentContractError') }],
                    })(
                      <Radio.Group
                        className="v2-radio-wrapper"
                        style={{ width: '100%' }}
                        onChange={e => this.handleTypeChange(e, 'contract_type')}
                        disabled={
                          [staffRequestStatuses.POSTED, staffRequestStatuses.IN_PROGRESS].includes(
                            staffRequest?.status,
                          ) &&
                          hasExistingContractType &&
                          isEdit
                        }
                      >
                        {indoContractTypeSelection.map((option, index) => (
                          <Radio key={index} value={option.value}>
                            {t(option.label)}
                          </Radio>
                        ))}
                      </Radio.Group>,
                    )}
                  </Item>
                </Col>
              </Row>
            )}
          </section>
        )}

        {currentStep === 2 && (
          <>
            <section style={{ marginBottom: '48px' }}>
              <Row style={{ marginBottom: 16, marginTop: 24 }}>
                <Switch
                  checked={applicationFormToggle}
                  disabled={!isDraftOrPendingReview && !!isEdit}
                  style={{ marginRight: 12 }}
                  onClick={checked => {
                    this.setState(prevState => {
                      // eslint-disable-next-line no-shadow
                      const { staffRequest } = prevState;
                      if (!checked) {
                        staffRequest.form_id = null;
                      }

                      return {
                        applicationFormToggle: checked,
                        staffRequest,
                        selectedApplicationForm: checked ? selectedApplicationForm : undefined,
                      };
                    });
                  }}
                />
                <Text strong>{t('applicationFormOptional')}</Text>
              </Row>
              <Row type="flex">
                <Col sm={24} md={colSpan}>
                  <Row style={{ marginBottom: 16 }}>
                    <Text style={{ color: colors.primaryText }}>{t('turnOnApplicationFormDescription')}</Text>
                  </Row>
                </Col>
                <Col sm={24}>
                  {applicationFormToggle && (
                    <ApplicationForms
                      rowSelection={{
                        type: 'radio',
                        onSelect: this.handleSelectForm,
                        selectedRowKeys: [...(selectedApplicationForm ? [selectedApplicationForm.id] : [])],
                        getCheckboxProps: () => ({ disabled: !isDraftOrPendingReview && !!isEdit }),
                      }}
                      onRefresh={this.handleRefresh}
                      showCreateButton={isWorkmateAccount}
                    />
                  )}
                </Col>
              </Row>
            </section>

            <Row>
              <Col sm={24} md={8}>
                <Alert
                  message={t('changesNotAllowedOnceJobIsPosted')}
                  type="info"
                  showIcon
                  style={{ marginBottom: 20 }}
                />
              </Col>
            </Row>
          </>
        )}

        {currentStep === 3 && (
          <section style={{ marginBottom: '48px' }}>
            <Row style={{ marginBottom: 16, marginTop: 24 }}>
              <Switch
                checked={firstDayMessageToggle}
                disabled={!isDraftOrPendingReview && !!isEdit}
                style={{ marginRight: 12 }}
                onClick={checked => {
                  this.setState(prevState => {
                    // eslint-disable-next-line no-shadow
                    const { staffRequest } = prevState;
                    if (!checked) {
                      staffRequest.first_day_message = null;
                    }

                    return { firstDayMessageToggle: checked, staffRequest };
                  });
                }}
              />
              <Text strong>{t('firstDayMessageOptional')}</Text>
            </Row>
            <Row type="flex">
              <Col sm={24} md={colSpan}>
                <Row style={{ marginBottom: 16 }}>
                  <Text style={{ color: colors.primaryText }}>{t('turnOnFirstDayMessageDescription')}</Text>
                </Row>
                <Row>
                  <Text style={{ color: colors.primaryText }}>{t('firstDayMessageInclude')}</Text>
                </Row>
                <Row style={{ marginBottom: 16 }}>
                  <ul style={{ paddingLeft: '24px', color: colors.primaryText }}>
                    <li>{t('firstDayMessageHint1')}</li>
                    <li>{t('firstDayMessageHint2')}</li>
                    <li>{t('firstDayMessageHint3')}</li>
                    <li>{t('firstDayMessageHint4')}</li>
                  </ul>
                </Row>
                {firstDayMessageToggle && (
                  <>
                    <Row>
                      <Item style={{ width: '40%' }} label={t('messageTitle')}>
                        <Input disabled value={t('firstDayMessageDefaultTitle')} />
                      </Item>
                    </Row>
                    <Row style={{ marginBottom: 16 }}>
                      <Item label={t('messageBody')} style={{ marginBottom: 0, width: '100%' }}>
                        {getFieldDecorator('first_day_message', {
                          validate: [
                            {
                              trigger: 'onBlur',
                              rules: [
                                {
                                  required: true,
                                  min: 50,
                                  message: t('firstDayMessageMinimumCharacterError'),
                                },
                                {
                                  max: 1000,
                                  message: t('firstDayMessageMaximumCharacterError'),
                                },
                              ],
                            },
                          ],
                          initialValue: !isDuplicate ? staffRequest?.first_day_message : undefined,
                        })(
                          <TextArea
                            placeholder={t('firstDayMessagePlaceholder')}
                            autoSize={{ minRows: 7, maxRows: 8 }}
                            style={{ width: '100%', marginBottom: 0 }}
                            onChange={debounce(
                              text => this.handleAddSrDetails(text, 'first_day_message'),
                              textInputDelay,
                            )}
                          />,
                        )}
                      </Item>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
              <Alert
                type="info"
                showIcon
                message={
                  firstDayMessageToggle ? t('firstDayMessageWarningToggleOn') : t('firstDayMessageWarningToggleOff')
                }
              />
            </Row>
          </section>
        )}

        {currentStep === 4 && (
          <section style={{ marginBottom: '48px' }}>
            <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
              <Text strong style={{ fontSize: '16px' }}>
                {t('jobDetails')}
              </Text>
            </Row>
            {(() => {
              const rows = [
                { label: t('Job position'), value: translatedPosition?.name || undefined },
                { label: t('workersNeeded'), value: staffRequest?.staff_required },
                { label: t('Business location'), value: staffRequest?.location?.name },
                {
                  label: t('applicants'),
                  value: staffRequest?.vip_workers_only ? t('vipWorkersOnly') : t('openToAnyone'),
                },
                {
                  label: t('interviewType'),
                  value: staffRequestUtils.getInterviewDescription(
                    staffRequest?.client_interview_required,
                    staffRequest?.auto_hire,
                    t,
                  ),
                },
                { label: t('jobTitle'), value: staffRequest?.title },
                { label: t('responsibilities'), value: this.newlineToLineBreak(staffRequest?.responsibilities) },
                { label: t('requirementsQualifications'), value: this.newlineToLineBreak(staffRequest?.requirements) },
                {
                  label: t('relevantWorkExperience'),
                  value: staffRequest?.require_experience ? t('needed') : t('notNeeded'),
                },
              ];
              return rows;
            })().map(field => (
              <Row key={field.label} style={{ marginBottom: '16px' }}>
                <Col xs={24} sm={24} md={8}>
                  <Text type="secondary">{field.label}</Text>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Text>{field.value}</Text>
                </Col>
              </Row>
            ))}

            <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
              <Text strong style={{ fontSize: '16px' }}>
                {t('employmentDetails')}
              </Text>
            </Row>
            {this.getEmploymentDetailsReviewData().map(field => (
              <Row key={field.label} style={{ marginBottom: '16px' }}>
                <Col xs={24} sm={24} md={8}>
                  <Text type="secondary">{field.label}</Text>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Row>{field.value}</Row>
                  {field.subtext && <Row>{field.subtext}</Row>}
                </Col>
              </Row>
            ))}
            {applicationFormToggle && selectedApplicationForm && (
              <>
                <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
                  <Text strong style={{ fontSize: '16px' }}>
                    {t('applicationForm')}
                  </Text>
                </Row>
                <Row key={t('applicationForm')} style={{ marginBottom: '16px' }}>
                  <Col xs={24} sm={24} md={8}>
                    <Text type="secondary">{t('formName')}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={16}>
                    <Text>
                      {selectedApplicationForm.title} (
                      <a
                        target="_blank"
                        href={routes.formPreview.replace(':id', selectedApplicationForm.id)}
                        style={{ color: colors.functionalLink }}
                        rel="noreferrer"
                      >
                        {t('preview')}
                        <img src={previewIcon} alt="preview" style={{ marginLeft: 6, width: 16, height: 16 }} />
                      </a>
                      )
                    </Text>
                  </Col>
                </Row>
              </>
            )}
            {firstDayMessageToggle && (
              <>
                <Row style={{ marginBottom: '16px', marginTop: '24px' }}>
                  <Text strong style={{ fontSize: '16px' }}>
                    {t('firstDayMessage')}
                  </Text>
                </Row>
                <Row key={t('firstDayMessage')} style={{ marginBottom: '16px' }}>
                  <Col xs={24} sm={24} md={8}>
                    <Text type="secondary">{t('messageTitle')}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={16}>
                    <Text>{t('firstDayMessageDefaultTitle')}</Text>
                  </Col>
                </Row>
                <Row key={t('firstDayMessage')} style={{ marginBottom: '16px' }}>
                  <Col xs={24} sm={24} md={8}>
                    <Text type="secondary">{t('messageContent')}</Text>
                  </Col>
                  <Col xs={24} sm={24} md={16}>
                    <Text>{this.newlineToLineBreak(staffRequest?.first_day_message)}</Text>
                  </Col>
                </Row>
              </>
            )}
          </section>
        )}
        {/* Footer action button */}
        {/* TODO: These buttons are exactly the same as the header. Should be refactored for consistency */}
        <Row style={{ marginBottom: '16px', maxWidth: '728px' }}>
          {currentStep > 0 && (
            <Button
              style={{ padding: '0 32px', marginRight: '10px', margin: '8px 10px 8px 0px' }}
              type="v2-secondary"
              name="submit"
              onClick={e => {
                e.preventDefault();
                form.validateFieldsAndScroll(async err => {
                  if (!err) {
                    this.setState(prevState => {
                      const nextStep = prevState.currentStep;
                      return { currentStep: nextStep - 1 };
                    });
                  }
                });
              }}
            >
              {t('back')}
            </Button>
          )}
          {currentStep <= 3 &&
            (() => {
              const nextButton = (
                <Button
                  {...(currentStep === 2 && applicationFormToggle && !selectedApplicationForm
                    ? { disabled: true }
                    : {})}
                  style={{ padding: '0 32px', marginRight: '10px', margin: '8px 10px 8px 0px' }}
                  type="v2-primary"
                  name="submit"
                  onClick={e => {
                    e.preventDefault();
                    form.validateFieldsAndScroll(async err => {
                      if (!err) {
                        this.setState(prevState => {
                          const nextStep = prevState.currentStep;

                          return { currentStep: nextStep + 1 };
                        });
                      }
                    });
                  }}
                >
                  {t('Next')}
                </Button>
              );

              if (currentStep === 2 && applicationFormToggle) {
                return (
                  <Tooltip placement="top" title={t('pleaseSelectAForm')}>
                    {nextButton}
                  </Tooltip>
                );
              }

              return nextButton;
            })()}
          {currentStep === 4 && !(isEdit && staffRequest.status !== staffRequestStatuses.DRAFT) && (
            <Button
              style={{ padding: '0 32px', marginRight: '10px', margin: '8px 10px 8px 0px' }}
              type="v2-primary"
              name="submit"
              onClick={e => {
                e.preventDefault();
                if (selectedTemplate) {
                  if (this.shouldUpdateTemplate()) {
                    this.setState({ updateJobTemplateModalOpen: true });
                  } else {
                    this.handleSubmit('submit');
                  }
                } else {
                  this.setState({ saveAsJobTemplateModalOpen: selectedTemplate === null });
                }
              }}
            >
              {t('postJobBtn')}
            </Button>
          )}
          {(isEdit || isDuplicate || currentStep > 0) && (
            <Button
              style={{
                padding: '0 16px',
                margin: '8px 10px 8px 0px',
                color: colors.functionalSuccess,
              }}
              className="saveDraftBtn"
              type="link"
              name="save"
              onClick={e => {
                e.preventDefault();
                form.validateFieldsAndScroll(async err => {
                  if (!err) {
                    this.handleSubmit('save');
                  }
                });
              }}
            >
              {isEdit ? t('Save') : t('saveDraft')}
            </Button>
          )}
        </Row>
        {/* Modal */}
        {locationModalOpen && (
          <LocationFormModal
            simplified
            clientId={user.clientId}
            country={user.country}
            onUpdate={fetchClientAndManager}
            location={null} // Does not have to be explicitly set in "this.state". Handled by Antd.
            visible={locationModalOpen}
            onClose={this.toggleLocationModal}
          />
        )}
        {saveAsJobTemplateModalOpen && (
          <SaveAsJobTemplateModal
            t={t}
            isVisible={saveAsJobTemplateModalOpen}
            handleClose={() => this.setState({ saveAsJobTemplateModalOpen: false })}
            handleOk={templateName => {
              this.setState(
                prevState => ({
                  staffRequest: {
                    ...prevState.staffRequest,
                    template_name: templateName,
                  },
                }),
                () => this.handleCreateOrUpdateTemplate('submit'),
              );
            }}
            handleCustomButtonClick={() => {
              if (selectedTemplate) {
                this.setState({ saveAsJobTemplateModalOpen: false, updateJobTemplateModalOpen: true });
              } else {
                this.handleSubmit('submit');
              }
            }}
            cancelText={selectedTemplate ? t('back') : t('noThanks')}
          />
        )}
        {updateJobTemplateModalOpen && (
          <UpdateCurrentTemplate
            t={t}
            isVisible={updateJobTemplateModalOpen}
            templateName={selectedTemplate?.template_name}
            handleOk={() => this.handleUpdateTemplate()}
            handleSaveAsNewTemplate={() =>
              this.setState({ updateJobTemplateModalOpen: false, saveAsJobTemplateModalOpen: true })
            }
            handleClose={() => this.setState({ updateJobTemplateModalOpen: false })}
            handleNoThanksPostJobBtn={() => this.handleSubmit('submit')}
          />
        )}
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    vipWorkerJobPosting: state.user.vipWorkerJobPosting,
  };
};

export default connect(mapStateToProps)(Form.create({ name: 'Staff Request' })(StaffRequestFormView));
