import React from 'react';
import { JOB_DETAIL_SIGN_CONTRACT_LINK } from './config';

export const messageDisplayDuration = 3;

export const EARTH_RADIUS_IN_KM = 6371; //
export const WORKER_APPLICATION_QUALIFIED_THRESHOLD = 0.9; // Application risk score

export const maxTextLengthBeforeEllipsis = 13;

export const DEFAULT_APPLICATION_EXPIRE_HOUR = 24;
export const MIN_PASSWORD_LENGTH = 12;

export const DEFAULT_CLOCKIN_RADIUS_IN_METERS = 1000;

export const selfieLocationStatus = {
  WARNING: 'warning',
  SUCCESS: 'success',
};

export const timesheetStatus = {
  CLOCK_IN: 'clock_in',
  CLOCK_OUT: 'clock_out',
};

export const timesheetEntrySources = {
  QR: 'qr',
  MANAGER: 'manager',
  MANUAL: 'manual',
};

export const timesheetDisputeReason = {
  NO_LOCATION_AND_SELFIE: ['no_location_and_selfie_clock_in', 'no_location_and_selfie_clock_out'],
  NO_LOCATION: ['no_location_clock_in', 'no_location_clock_out'],
  WRONG_LOCATION: ['wrong_location_clock_in', 'wrong_location_clock_out'],
};

export const sourceOptions = {
  GRAB: 'Grab',
};

export const employmentStatuses = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  ENDED: 'ended',
};

export const employmentCancellationReasons = [
  {
    title: 'Moving to another SR',
    titleKey: 'movingToAnotherSR',
    description: 'Worker is being moved to another SR',
    descriptionKey: 'movingToAnotherSRDescription',
    value: 'staff_request_reassignment',
  },
  {
    title: 'Company reduced demand',
    titleKey: 'companyReducedDemand',
    description: 'Company requires less workers now',
    descriptionKey: 'companyReducedDemandDescription',
    value: 'reduced_demand',
  },
  {
    title: 'Worker did not show up',
    titleKey: 'workerDidNotShowUp',
    description: 'Worker did not show up at all for the job',
    descriptionKey: 'workerDidNotShowUpDesciption',
    value: 'no_show',
  },
  {
    title: 'Worker quit without notice',
    titleKey: 'workerQuitWithoutNotice',
    description: 'Worker stopped showing up after one or more days of work',
    descriptionKey: 'workerQuitWithoutNoticeDescription',
    value: 'absence_without_notice',
  },
  {
    title: 'Worker submitted resignation',
    titleKey: 'workerSubmittedResignation',
    description: 'Worker quit or submitted resignation with notice',
    descriptionKey: 'workerSubmittedResignationDescription',
    value: 'resigned',
  },
  {
    title: 'Worker was underperforming',
    titleKey: 'workerWasUnderPerforming',
    description: 'Worker was not performing sufficiently well for the job',
    descriptionKey: 'workerWasUnderPerformingDescription',
    value: 'underperformance',
  },
  {
    title: 'Worker displayed inappropriate behaviour',
    titleKey: 'workerDisplayedInappropriateBehaviour',
    description: 'Worker displayed inappropriate behaviour while on the job e.g. Lost uniform, broke equipment',
    descriptionKey: 'workerDisplayedInappropriateBehaviourDescription',
    value: 'inappropriate_behaviour',
  },
];

export const applicationStatuses = {
  PENDING_ONBOARDING: 'pending_onboarding',
  APPLIED: 'applied',
  PENDING_CONTRACT: 'pending_contract',
  REJECTED: 'rejected',
  WITHDRAWN: 'withdrawn',
  HIRED: 'hired',
  SHORTLISTED: 'shortlisted',
  INTERVIEW: 'interview',
};

// Keys are used in localStorage for set/get
export const defaultPageSizeKeys = {
  TIMESHEET_LIST: 'timesheet_list_page_size',
};

export const billingReportStatuses = {
  BILLED: 'billed',
  HOLD: 'hold',
  UNBILLED: 'unbilled',
};

export const billingSchedule = {
  WEEKLY: 'weekly',
};

export const cardTypes = {
  AMERICAN_EXPRESS: 'Amex',
  VISA: 'Visa',
  MASTERCARD: 'Master',
  DISCOVER: 'Discover',
};

export const countryCodeOptions = {
  SINGAPORE: 'SG',
  INDONESIA: 'ID',
  THAILAND: 'TH',
  INDIA: 'IN',
};

export const languageOptions = {
  ENGLISH: 'en',
  BAHASA: 'id',
  THAI: 'th',
};

export const settingsTabs = {
  COMPANY: 'company',
  BILLING: 'billing',
  LEGAL: 'legal',
  TEAM: 'team-management',
  ROLES: 'roles',
  LOCATIONS: 'locations',
  TIMESHEET_TAGS: 'timesheet-tags',
  LANGUAGE: 'language',
};

export const paymentMethods = {
  CREDIT: 'credit',
  INVOICE: 'invoice',
  PREPAID: 'prepaid',
  ONLINE_DIRECT_PAYMENT: 'online_direct_payment',
  ONLINE_INVOICE_PAYMENT: 'online_invoice_payment',
};

export const paymentFrequency = {
  WEEKLY: { key: 'weekly', title: 'Weekly' },
  BIWEEKLY: { key: 'bi-weekly', title: 'Bi-weekly' },
  MONTHLY: { key: 'monthly', title: 'Monthly' },
};

export const genderOptions = {
  MALE: { key: 'male', title: 'Male' },
  FEMALE: { key: 'female', title: 'Female' },
  NOT_PROVIDED: { key: 'unknown', title: 'Not Provided' },
};

export const paymentServices = {
  OMISE: 'omise',
  XENDIT: 'xendit',
};

export const seenModals = {
  INTRO: 'seenOnboardingIntro',
  FIRST_SR: 'seenOnboardingFirstSR',
  SHOW_SUBMITTED_SR: 'showSubmittedSrModal',
};

export const staffRequestTabs = {
  SUMMARY: 'summary',
  OFFERS: 'offered',
  APPLIED: 'applied',
  EMPLOYEES: 'employees',
  TIMESHEET: 'timesheet',
  SHORTLISTED: 'shortlisted',
  INTERVIEW: 'interview',
  INACTIVE: 'inactive',
  WAITLISTED: 'waitlisted',
  ACCEPTED: 'accepted',
  CANCELLED: 'cancelled',
};

export const staffRequestStatuses = {
  DRAFT: 'draft',
  PENDING_REVIEW: 'pending_review',
  POSTED: 'posted',
  IN_PROGRESS: 'in_progress',
  ENDED: 'ended',
  CANCELLED: 'cancelled',
};

export const roles = {
  SUPERVISOR: 'supervisor',
  ADMIN: 'admin',
};

export const statusFilterOptions = [
  { option: 'Draft', statuses: `${staffRequestStatuses.DRAFT}` },
  { option: 'Submitted', statuses: `${staffRequestStatuses.PENDING_REVIEW}` },
  { option: 'Active', statuses: `${staffRequestStatuses.IN_PROGRESS},${staffRequestStatuses.POSTED}` },
  { option: 'Past', statuses: `${staffRequestStatuses.ENDED},${staffRequestStatuses.CANCELLED}` },
];

export const offerStatuses = {
  SENT: 'sent',
  VIEWED: 'viewed',
  APPLIED: 'applied',
  PENDING_ONBOARDING: 'pending_onboarding',
  PENDING_CONTRACT: 'pending_contract',
  WITHDRAWN: 'withdrawn',
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  NO_SHOW: 'no_show',
};

export const userStatuses = {
  LEAD: 'lead',
  ACTIVE: 'active',
  REJECTED: 'rejected',
  SUSPENDED: 'suspended',
  DISABLED: 'disabled',
};

export const paymentStatuses = {
  UNPAID: 'unpaid',
  ERROR: 'error',
  PAID: 'paid',
};

export const paymentTypes = {
  BASE: 'base',
  OVERTIME: 'overtime',
};

export const workerTags = {
  RECOMMENDED: 'recommended',
  NEW_JOINER: 'new_joiner',
  COVID_VACCINATED: 'covid_vaccinated',
  PAST_WORKER: 'past_worker',
  EXPERIENCED: 'experienced',
  DAYS_WORKED: 'days_worked',
  VERIFIED: 'verified',
  SKILL_SCORE: 'skill_score',
  RATING: 'rating',
  JOB_INVITATION: 'job_invitation',
  AUTO_HIRED: 'auto_hired',
};

export const workerCovidCertificationStatuses = {
  VERIFIED: 'verified',
  INVALID: 'invalid',
  PENDING_VERIFICATION: 'pending_verification',
  ALL_UNVERIFIED_STATUSES: 'invalid,pending_verification,null', // Used as filter option for handling multiple statuses
};

export const timesheetEntryStatuses = {
  CLOCKED_IN: 'clocked_in',
  CLOCKED_OUT: 'clocked_out',
  APPROVED: 'approved',
  DISPUTED: 'disputed',
  RESOLVED: 'resolved',
  VOID: 'void',
};

export const timesheetEntryStatusList = [
  timesheetEntryStatuses.CLOCKED_IN,
  timesheetEntryStatuses.CLOCKED_OUT,
  timesheetEntryStatuses.DISPUTED,
  timesheetEntryStatuses.RESOLVED,
  timesheetEntryStatuses.APPROVED,
  timesheetEntryStatuses.VOID,
];

export const timesheetEntryPaymentStatus = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  IN_PROGRESS: 'in_progress',
};

export const leaveEntryStatuses = {
  REQUESTED: 'requested',
  CONFIRMED: 'confirmed',
  APPROVED: 'approved',
  DISPUTED: 'disputed',
  VOID: 'void',
  REJECTED: 'rejected',
};

export const leaveEntryPaymentStatus = {
  PAID: 'paid',
  UNPAID: 'unpaid',
};

export const leaveEntryVoidReasonCodes = {
  SHIFT_SCHEDULE_CHANGES: 'shift_schedule_changes',
  WORKER_CANCELLED_LEAVE: 'worker_cancelled_leave',
  INSUFFICIENT_LEAVE_BALANCE: 'insufficient_leave_balance',
  WRONG_LEAVE_SUBMITTED: 'wrong_leave_submitted',
  OTHER: 'other',
};

export const DEFAULT_TIME_FORMAT = 'hh:mmA';
export const DEFAULT_DATE_FORMAT = 'D MMM YYYY';
export const DEFAULT_DATETIME_FORMAT = 'DD/MM/YYYY hh:mm A';
export const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const DATE_WITH_DAY_FORMAT = 'ddd, D MMM YYYY';
export const MINIMAL_TIME_FORMAT = 'hh:mma';
export const DATE_FORMAT = 'DD MMM YYYY';
export const DATE_MONTH_FORMAT = 'MMMM YYYY';
export const DURATION_FORMAT = 'hh:mm:ss';
export const TIME_FORMAT_24_HOUR = 'HH:mm';
export const TIME_FORMAT_24_HOUR_WITH_SECONDS = 'HH:mm:ss';
export const DATE_KEY_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT_24_HOUR = 'DD MMM, HH:mm';
export const DATE_FORMAT_WITH_TIME = 'YYYY-MM-DD HH-mm-ss';
export const SERVER_DATE_FORMAT_WITH_TIME = 'YYYY-MM-DD HH:mm';
export const ANALYTICS_DATE_FORMAT = 'YYYY-MM-DD';

export const PREPAID_TAX_RATE = 10;
export const PREPAID_SERVICE_RATE = 15;

export const indoBankAccountDetail = {
  payeeName: 'PT. Helpster Solutions Indonesia',
  accountNumber: '1021172851',
  bankName: 'Bank Sahabat Sampoerna',
  bankAddress: (
    <>
      Sampoerna Strategic Square,
      <br />
      North Tower, Mezzanine Floor
      <br />
      Jl. Jend. Sudirman Kav. 45,
      <br />
      Jakarta 12930
    </>
  ),
};

export const wageTypes = {
  PER_HOUR: {
    value: 'per_hour',
    label: 'Hourly',
  },
  PER_DAY: {
    value: 'per_day',
    label: 'Daily',
  },
  PER_MONTH: {
    value: 'per_month',
    label: 'Monthly',
  },
  PER_JOB: {
    value: 'per_job',
    label: 'Job',
  },
};

export const employmentTypes = {
  FULL_TIME: {
    value: 'full_time',
    label: 'employmentTypeFullTime',
  },
  PART_TIME: {
    value: 'part_time',
    label: 'employmentTypePartTime',
  },
  DAILY: {
    value: 'daily',
    label: 'employmentTypeDaily',
  },
  CONTRACT: {
    value: 'contract',
    label: 'employmentTypeContract',
  },
  GIG: {
    value: 'gig',
    label: 'employmentTypeGig',
  },
};

export const staffRequestContractStatus = {
  PENDING_SIGNATURE: 'pending_signature',
  SIGNED: 'signed',
  EXPIRED: 'expired',
  VOID: 'void',
};

export const derivedWorkingStatus = {
  EMPLOYMENT_NOT_STARTED: 'employment_not_started',
  EMPLOYMENT_ENDED: 'employment_ended',
  RESIGNED: 'resigned',
  CONTRACT_NOT_SIGNED: 'contract_not_signed',
  ON_BREAK: 'on_break',
  WORKING: 'working',
  ABSENT: 'absent',
};

export const analyticsTabs = {
  COSTS: 'costs',
  PERFORMANCE: 'performance',
  FULFILMENT: 'fulfilment',
  REPORT: 'report',
};

export const recurrenceOptions = [
  { value: 6, label: 'Sun' },
  { value: 0, label: 'Mon' },
  { value: 1, label: 'Tue' },
  { value: 2, label: 'Wed' },
  { value: 3, label: 'Thu' },
  { value: 4, label: 'Fri' },
  { value: 5, label: 'Sat' },
];

export const attendanceOffScheduleCode = '-1';

export const attendanceStatuses = {
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
  CANCELLED: 'cancelled',
};

export const attendanceAbsenceReasons = {
  SICK: 'sick',
  PERSONAL_LEAVE: 'personal_leave',
  NO_SHOW: 'no_show',
};

export const unsetCode = -1;

export const assignmentStatuses = {
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
  LEAVE: 'leave',
  NO_SHOW: 'No Show',
  NO_SHIFT_THIS_WEEK: 'noShiftThisWeek',
  NO_SHIFT_THIS_MONTH: 'noShiftThisMonth',
};

export const zendeskLinks = {
  BUSINESS_HELP_CENTER_EN: 'https://workmateasia.zendesk.com/hc/en-us/categories/900000158986-Business',
  CONTACT_US_EN: 'https://workmateasia.zendesk.com/hc/en-us/articles/900002880663-Contact-Us',
  SHIFT_HELP_CENTER_ID: 'https://workmateasia.zendesk.com/hc/id-id/articles/900002475726-3-Shift-Management',
  SHIFT_HELP_CENTER_TH:
    'https://workmateasia.zendesk.com/hc/th/articles/900002475726-3-%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%88%E0%B8%B1%E0%B8%94%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%81%E0%B8%B0-%E0%B8%A5%E0%B8%B9%E0%B8%81%E0%B8%84-%E0%B8%B2',
};

export const shiftFullfillments = {
  NEEDED: 'Needed',
  ASSIGNED: 'Assigned',
  WORKED: 'Worked',
};

export const partnerSourceTypes = {
  WEBSITE: 'website',
  SALES: 'sales',
  REFERRED: 'referred',
  INTERNET_SEARCH: 'internet_search',
  JOB_BOARD: 'job_board',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  BLOG: 'blog',
  DIRECT_HIRE: 'direct_hire',
  DIRECT_JOB_INVITE: 'direct_job_invite',
  OTHERS: 'others',
};

export const workerTypeOptions = [
  {
    key: workerTags.VERIFIED,
    title: 'Workmate Verified',
  },
  {
    key: workerTags.JOB_INVITATION,
    title: 'Job invitation',
  },
];

export const moreFilterOptions = [
  {
    key: workerTags.RECOMMENDED,
    title: 'Recommended',
    localeKey: 'recommended',
  },
  // temporary hide auto filter
  // {
  //   key: workerTags.AUTO_HIRED,
  //   title: 'Processed by Autohire',
  //   localeKey: 'processedByAutoHire',
  // },
];

export const qrClockInOptions = [
  {
    name: 'Manual worker clock-in',
    description: ' Workers clock-in themselves on the worker app.',
    value: false,
  },
  {
    name: 'QR code scanning',
    description: 'Workers clock-in via QR code scanning by on-ground Supervisors.',
    value: true,
  },
];

export const httpResponseStatuses = {
  OK_200: 200,
  CREATED_201: 201,
  BAD_REQUEST_400: 400,
  UNAUTHORIZED_401: 401,
  FORBIDDEN: 403,
};

export const errorCodes = {
  USER_ACCOUNT_IS_NOT_ACTIVE: 'user_account_is_not_active',
  MOBILE_ALREADY_EXISTS: 'mobile_already_exists',
  INVALID_MOBILE: 'invalid_mobile',
  ACTIVE_EMPLOYMENT_ALREADY_EXISTS: 'active_employment_already_exists',
  INACTIVE_MANAGER_RECORD_ALREADY_EXISTS: 'inactive_manager_record_already_exists',
  MANAGER_ALREADY_EXISTS_FOR_CLIENT: 'manager_already_exists_for_client',
  CHANNEL_NAME_EMPTY: 'channel_name_empty',
  CHANNEL_DOES_NOT_HAVE_MEMBERS: 'channel_does_not_have_members',
  USER_ALREADY_EXISTS: 'user_already_exists',
  INVALID_REFERRAL_CODE: 'invalid_referral_code',
};

export const errorDetails = {
  SR_ID_NOT_FOUND: 'Not found.',
};

// error messages are directly from the manager write serializer validation errors
export const userValidationErrors = {
  EMAIL_ALREADY_EXISTS: 'Manager with this email already exists',
  ENTER_A_VALID_USERNAME:
    'Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters.',
};

export const mobileCountryCodes = {
  CN: { label: '🇨🇳CN', length: 13, example: '+86 318 4567 8912' },
  ID: { label: '🇮🇩ID', length: 12, example: '+62 801 4561 7890' },
  SG: { label: '🇸🇬SG', length: 8, example: '+65 4802 5188' },
  TH: { label: '🇹🇭TH', length: 11, example: '+66 081 2328 8037' },
  IN: { label: '🇮🇳IN', length: 10, example: '+91 91 3801 8018' },
};

export const countryIds = {
  ID: 1,
  SG: 3,
  TH: 2,
  IN: 4,
};

export const WEEKLY_DATE_RANGE = 'week';
export const MONTHLY_DATE_RANGE = 'month';
export const DAY = 'day';

export const LARGE_DATASET_PAGESIZE = 1000;

export const THAILAND_TIMEZONE = 'Asia/Bangkok';
export const INDONESIA_TIMEZONE = 'Asia/Jakarta';

export const SHIFTS_SCHEDULE_TABLE_VT_ID = 2000;
export const SHIFTS_ASSIGNMENT_TABLE_VT_ID = 1000;

export const audiences = {
  APPLIED_APPLICANTS: 'applied_applicants',
  ACTIVE_EMPLOYEES: 'active_employees',
  SELECTED_APPLICANTS: 'selected_applicants',
};

export const inactiveStatusOptions = {
  REJECTED: { key: 'rejected', title: 'Rejected' },
  WITHDRAWN: { key: 'withdrawn', title: 'Withdrawn' },
};

export const acceptedStatusOptions = {
  ACCEPTED: { key: 'active', title: 'Hired' },
  ENDED: { key: 'ended', title: 'Ended' },
  CANCELLED: { key: 'cancelled', title: 'Cancelled' },
};

export const channelStatuses = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const APPROVED_EMAIL = 'approved_email';
export const NEW_APPLICATIONS_EMAIL = 'new_applications_email';
export const NEW_MESSAGES_EMAIL = 'new_messages_email';
export const TOTAL_NEW_APPLICATIONS = 'total_new_applications';
export const TOTAL_UNREAD_CHATS = 'total_unread_chats';
export const PARTNER_ID = 'pid';

// Value is linked with hubspot contact property "industry_signup"
export const HUBSPOT_INDUSTRY_VALUE = {
  food_and_beverage: 'F&B',
  warehouse_and_logistics: 'Logistics & warehousing',
  manufacturing: 'Manufacturing',
  retail: 'Retail',
  hospitality: 'Hospitality',
  events: 'Events & Promotion',
  outsourcing_agency: 'Outsourcing Agency',
  others: 'Other',
};

export const autoRejectStatuses = {
  failedToSignContract: 'failed_to_sign_contract',
  applicationExpired: 'application_expired',
};

export const autoWithdrawnStatuses = {
  jobPostClosedByClient: 'auto_jp_cancelled',
};

export const allowedDomains = ['@workmate.asia', '@helpster.asia', '@workmate.sg'];

export const smsCopytextTranslations = {
  SG: (clientName, id) => {
    return `Congrats! You have an offer from ${clientName}. Tap here to accept a job offer in Workmate app ASAP ${JOB_DETAIL_SIGN_CONTRACT_LINK}job_id=${id}`;
  },
  ID: (clientName, id) => {
    return `Selamat! Anda mendapat tawaran dari ${clientName}. Ketuk di sini untuk menerima tawaran kerja dalam app Workmate ${JOB_DETAIL_SIGN_CONTRACT_LINK}job_id=${id}`;
  },
  TH: (clientName, id) => {
    return `ยินดีด้วย! คุณได้งานจาก ${clientName} กดที่นี่เพื่อรับข้อเสนองานในแอป Workmate ตอนนี้ ${JOB_DETAIL_SIGN_CONTRACT_LINK}job_id=${id}`;
  },
};

export const resendOfferInAppNotificationTranslations = {
  SG: (clientName, workerName) => {
    return `Hi ${workerName}, just a friendly reminder that ${clientName} has sent you a job offer! Please Accept or Reject within the Workmate App before the offer expires!`;
  },
  ID: (clientName, workerName) => {
    return `Hi ${workerName}, Anda punya tawaran kerja dari ${clientName}! Jangan lupa untuk terima atau tolak lamaran via Workmate App sebelum hangus`;
  },
  TH: (clientName, workerName) => {
    return `สวัสดี ${workerName} นี่เป็นข้อความแจ้งเตือนว่าคุณได้รับการจ้างงานจาก ${clientName} กรุณาตอบรับข้อเสนองานโดยเร็ว ก่อนข้อเสนองานนี้จะหมดอายุ`;
  },
};

export const inAppNotificationTranslations = {
  SG: (clientName, jobTitle) => {
    return `Congrats, you have an offer from ${clientName}. Click here and accept the offer now for ${jobTitle}`;
  },
  ID: (clientName, jobTitle) => {
    return `Selamat! Kamu mendapatkan penawaran dari ${clientName}. Klik di sini untuk menerima penawaran untuk posisi ${jobTitle}`;
  },
  TH: (clientName, jobTitle) => {
    return `ยินดีด้วย คุณได้รับข้อเสนองานจาก ${clientName}. กดที่นี่เพื่อตอบรับข้อเสนองานสำหรับ ${jobTitle}`;
  },
};

export const localStorageKeys = {
  TOKEN: 'token',
  MANAGER_ID: 'manager_id',
  SHIFT_FILTERS: 'shift_filters',
};

export const sessionStorageKeys = {
  MANAGER_ID: 'manager_id',
};

export const choiceTypes = {
  MULTI_SELECT: 'multi_select',
  SINGLE_SELECT: 'single_select',
  FREE_TEXT: 'free_text',
};

export const choiceTypesWithKeys = {
  FREE_TEXT: { key: 'free_text', title: 'Free text', transKey: 'freeText' },
  SINGLE_SELECT: { key: 'single_select', title: 'Single select', transKey: 'singleSelect' },
  MULTI_SELECT: { key: 'multi_select', title: 'Multi select', transKey: 'multiSelect' },
};

export const createQuestionMoreActionTypes = {
  DESCRIPTION: 'description',
  SCORE_QUESTION: 'score_question',
  ATTACH_IMAGE: 'attach_image',
};

export const TAFEP_GUIDELINES_LINK = 'https://www.tal.sg/tafep/getting-started/fair/tripartite-guidelines';

export const questionStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const formStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const MAX_QUESTION_DESCRIPTION_LENGTH = 1000;

export const imageTypes = {
  PNG_IMAGE: 'image/png',
  JPG_IMAGE: 'image/jpg',
  JPEG_IMAGE: 'image/jpeg',
};

export const interviewOptionKeys = {
  No: {
    value: false,
    transKey: 'interviewOptionNo',
  },
  Yes: {
    value: true,
    transKey: 'interviewOptionYes',
  },
};

export const staffRequestTemplateStatuses = {
  DRAFT: 'draft',
  POSTED: 'posted',
};

export const SAVE_JOB_AS_TEMPLATE_MODAL = 'saveJobAsTemplateModal';
export const UPDATE_JOB_TEMPLATE_MODAL = 'updateJobTemplateModal';

export const clockinMethods = {
  QR_SCAN_ONLY: 'qr_scan_only',
  QR_SCAN_OR_SELFIE: 'qr_scan_or_selfie',
};

export const absenceDetailStatuses = {
  CANCELLED: 'cancelled',
  SUBMITTED: 'submitted',
};

export const staffRequestContractTypes = {
  TH: {
    SSO: 'SSO',
    WHT: 'WHT',
  },
  ID: {
    PARTNERSHIP: 'partnership',
    PKHL: 'PKHL',
    PKWT: 'PKWT',
  },
};

export const indoContractTypeSelection = [
  {
    value: staffRequestContractTypes.ID.PARTNERSHIP,
    label: staffRequestContractTypes.ID.PARTNERSHIP,
  },
  {
    value: staffRequestContractTypes.ID.PKHL,
    label: staffRequestContractTypes.ID.PKHL,
  },
  {
    value: staffRequestContractTypes.ID.PKWT,
    label: staffRequestContractTypes.ID.PKWT,
  },
];

export const PAGE_SIZE_OPTIONS = ['10', '20', '50', '100'];

export const jobPostTypes = {
  REGULAR: 'regular',
  GIG: 'gig',
};

export const clientTypes = {
  MANAGED_SERVICE: 'managed_service',
  SELF_SERVE: 'self_serve',
};

export const pricingUnitTypes = {
  FLAT_RATE: 'flat_rate',
  PERCENTAGE: 'percentage',
};

export const sourceTypes = {
  WEB_CLIENT_PORTAL: 'Web Client Portal',
};

export const AttendanceStatus = {
  CLOCKED_IN: 'clocked_in',
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
  DISPUTED: 'disputed',
  APPROVED: 'approved',
};

export const jobTypes = {
  REGULAR_JOBS: 'regular_jobs',
  GIGS: 'gigs',
};

export const shiftStatuses = {
  POSTED: 'posted',
  IN_PROGRESS: 'in_progress',
  ENDED: 'ended',
  CANCELLED: 'cancelled',
};

export const cardBrandTypes = {
  AMERICAN_EXPRESS: 'amex',
  VISA: 'visa',
  MASTERCARD: 'master',
  DISCOVER: 'discover',
};

export const gigContractTypes = {
  FREELANCE: 'freelance',
};

export const clientStatuses = {
  PILOT: 'pilot',
  ACTIVE: 'active',
};

export const queryKeys = {
  COUNTRY_INFO: ['country_info'],
  UNPAID_ONLINE_PAYMENT_INVOICE: ['unpaid_online_payment_invoice'],
};

export const countryMinWageAmounts = {
  [countryCodeOptions.SINGAPORE]: {
    [wageTypes.PER_DAY.value]: 0,
    [wageTypes.PER_HOUR.value]: 10,
  },
  [countryCodeOptions.THAILAND]: {
    [wageTypes.PER_DAY.value]: 343,
    [wageTypes.PER_HOUR.value]: 0,
  },
  [countryCodeOptions.INDONESIA]: {
    [wageTypes.PER_DAY.value]: 0,
    [wageTypes.PER_HOUR.value]: 0,
  },
};

export const onlinePaymentMethods = [paymentMethods.ONLINE_DIRECT_PAYMENT, paymentMethods.ONLINE_INVOICE_PAYMENT];

export const roundingTypes = {
  NEAREST: 'NEAREST',
  DOWN: 'DOWN',
  SHIFT: 'SHIFT',
};

export const roundingTypesTranslation = {
  NEAREST: 'nearest',
  DOWN: 'downTo',
};

export const FEATURE_FLAGS = {
  KEYS: {
    SHOW_CLIENT_DASHBOARD_VERSION: 'show-client-dashboard-version',
  },
};

export const EVENTS_FLAGS = {
  SIGN_IN: 'sign_in',
};
