import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Typography } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import { withTranslation } from 'react-i18next';

import AssignmentTableCell from './AssignmentTableCell';
import routes from '../../../routes';
import { colors } from '../../../styles/colors';
import textUtils from '../../../utilities/textUtils';

const { Text } = Typography;
const defaultSort = 'ascend';
const y = 400;

const AssignmentTable = ({
  onSelect,
  onUpdate,
  loading,
  dayDates,
  dataSource,
  selectedRowKeys,
  filteredPartnerIds,
  timezone,
  t,
}) => {
  const [vt] = useVT(() => ({ scroll: { y }, overscanRowCount: 3 }), []);

  const handleSelectChange = selectedRowKeyValues => {
    onSelect(selectedRowKeyValues);
  };

  const handleAttendanceUpdate = update => {
    onUpdate(update);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };

  const tableColumns = [
    {
      title: <Text strong>{t('worker')}</Text>,
      dataIndex: 'worker',
      width: 170,
      filteredValue: filteredPartnerIds,
      onFilter: (value, record) => record.id.toString() === value.toString(),
      defaultSortOrder: defaultSort,
      sorter: (a, b) =>
        `${a.worker.first_name} ${a.worker.last_name}`.localeCompare(`${b.worker.first_name} ${b.worker.last_name}`),
      render: worker => (
        <Link to={routes.partnerDetail.replace(':id', worker.id)} target="_blank">
          <Text strong>{textUtils.toTitleCase(`${worker.first_name} ${worker.last_name}`)}</Text>
        </Link>
      ),
    },
    {
      title: <Text strong>{t('days')}</Text>,
      width: 60,
      dataIndex: 'assignedCount',
      defaultSortOrder: defaultSort,
      sorter: (a, b) => a.assignedCount - b.assignedCount,
      render: assignedCount => (
        <div style={{ width: 115, whiteSpace: 'normal' }}>
          <Text style={{ lineHeight: 1 }}>{assignedCount}</Text>
        </div>
      ),
    },

    // Each day is a column of attendances that belong to the shift of that day
    ...dayDates.map((date, index) => {
      return {
        title: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style={{ color: date.isSame(moment(), 'day') && colors.functionalSuccess }}>
              {date.format('ddd')}
            </Text>
            <Text style={{ color: date.isSame(moment(), 'day') && colors.functionalSuccess }} strong>
              {date.format('DD MMM')}
            </Text>
          </div>
        ),
        width: 100,
        render: (attendance, record) => ({
          props: { style: { padding: 0 }, width: '100' },
          children: (
            <AssignmentTableCell
              employmentId={record.employmentId}
              worker={record.worker}
              assignment={record.assignments[index]}
              onUpdate={handleAttendanceUpdate}
              timezone={timezone}
            />
          ),
        }),
      };
    }),
  ];

  return (
    <Table
      scroll={{ y }}
      rowSelection={rowSelection}
      pagination={false}
      loading={loading}
      columns={tableColumns}
      dataSource={dataSource}
      components={vt}
      rowKey="id"
      size="middle"
    />
  );
};

export default withTranslation()(AssignmentTable);
